// names are taken from https://www.color-blindness.com/color-name-hue/
export const colors = {
  white: '#FFF',
  black: '#000',
  black23: 'rgba(0, 0 ,0, 0.23)',
  darkGrey: '#797979',
  whiteSmoke: '#F9F9F9',
  whiteSmoke2: '#EAEAEA',
  whiteSmoke3: '#f2f2f2',
  echoBlue: '#9DB1C4',
  blueBayoux: '#5f7080',
  blueBayoux2: '#5f708060',
  blueBayoux56: 'rgba(95, 112, 128, 0.56)',
  nightRider: '#333333',
  thunderbird: '#8E3030',
  mediumBlue: '#F8FAFC',
  periwinkle: '#c1d3e3',
  zircon: '#DFE0E2',
  solitude: '#F0F4F8',
  silver: '#c2c2c2',
  clearDay: '#F2F6F9',
  acadia: '#5f7080',
  narvik: '#F7F6F3',
  navyBlue: '#347cf6',
  dimGray: '#626262',
} as const
