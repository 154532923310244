import { MayBeNull } from '@wpp-open/core'
import { useParams } from 'react-router-dom'

import { useWidgetApi } from 'api/widgets/queries/useWidgetApi'
import { WidgetType } from 'types/widgets/enums'
import { WidgetDTO } from 'types/widgets/widget'

export const useWidgetData = <CurrentWidgetType extends WidgetDTO>() => {
  const { widgetId } = useParams()
  const {
    data: widget,
    isLoading: isWidgetLoading,
    isError,
    error,
  } = useWidgetApi({
    params: { widgetId: widgetId! },
    enabled: !!widgetId,
    staleTime: 180000, // 3min
  })

  return {
    widget: widget as MayBeNull<CurrentWidgetType>,
    isNativeWidget: widget?.widgetType === WidgetType.NATIVE,
    isWidgetLoading,
    isWidgetError: isError,
    widgetError: error,
  }
}
