import { masterDataApi, userDetailsProxyApi, devHubApi } from 'api'
import { ApiInstance } from 'api/common/createApi'
import { ApiErrorTypes } from 'constants/apiErrors'
import { API } from 'types/common/enums'
import { ErrorState } from 'ui-base/errorState/ErrorState'

export const getApiInstance = (api: API): ApiInstance => {
  switch (api) {
    case API.DEVHUB:
      return devHubApi

    case API.MASTER_DATA:
      return masterDataApi

    case API.CORE_FE_PROXY:
      return userDetailsProxyApi

    default:
      return null as never
  }
}

export const handleApiErrors = ({
  error,
  styles,
  fullHeight,
}: {
  error: any
  styles?: Record<string, string>
  fullHeight?: boolean
}) => {
  const status = error?.response?.status

  if (status === 403) {
    return <ErrorState errorType={ApiErrorTypes.ACCESS_DENIED} {...styles} fullHeight={fullHeight} />
  } else if (status === 404) {
    return <ErrorState errorType={ApiErrorTypes.NOT_FOUND} {...styles} fullHeight={fullHeight} />
  } else if (status! >= 500) {
    return <ErrorState errorType={ApiErrorTypes.SERVER_ERROR} {...styles} fullHeight={fullHeight} />
  } else {
    return <ErrorState errorType={ApiErrorTypes.OTHER_ERROR} {...styles} fullHeight={fullHeight} />
  }
}
