import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useOs } from '@wpp-open/react'
import { PropsWithChildren, useState } from 'react'

import { apiInstances } from 'api'
import { useAuth } from 'hooks/useAuth'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 20,
    },
  },
})

export const ApiProvider = ({ children }: PropsWithChildren) => {
  const { getAccessToken } = useAuth()
  const {
    osContext: {
      tenant: { id },
    },
  } = useOs()

  useState(() =>
    apiInstances.forEach(instance => {
      instance.client.interceptors.request.use(
        config => {
          const token = getAccessToken()

          if (token) {
            config!.headers!.Authorization = `Bearer ${token}`
          }

          config.headers!['Current-Tenant-Id'] = id

          return config
        },
        error => Promise.reject(error),
      )

      instance.client.interceptors.response.use(
        response => response,
        error => Promise.reject(error),
      )
    }),
  )

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  )
}
