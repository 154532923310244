import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { WppTypography } from '@platform-ui-kit/components-library-react'

const commonStyles = css`
  // Fallback for Safari
  overflow: hidden;
  overflow: clip;
  text-overflow: ellipsis;
  word-break: break-word;
`

export const SingleLine = styled(WppTypography)`
  display: block;
  white-space: nowrap;
  ${commonStyles}
`

export const Multiline = styled(WppTypography)`
  &::part(typography) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--lines);
    ${commonStyles}
  }
`
