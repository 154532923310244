import { devHubApi } from 'api'
import { NativeWidgetFormSubmitDTO, WidgetDTO } from 'types/widgets/widget'

interface Params {
  widgetId: string
  widgetData: NativeWidgetFormSubmitDTO
}

export const updateWidgetApi = ({ widgetId, widgetData }: Params) =>
  devHubApi.put<WidgetDTO>(`/widgets/${widgetId}`, widgetData)
