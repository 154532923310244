import { LabelConfig } from '@platform-ui-kit/components-library'
import { WppFileUploadCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import {
  FileUploadEventDetail,
  FileUploadLocales,
} from '@platform-ui-kit/components-library/dist/types/components/wpp-file-upload/types'
import { WppFileUpload, WppLabel } from '@platform-ui-kit/components-library-react'
import { ComponentProps, forwardRef, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'
import { useWebComponentRef } from 'hooks/form/useWebComponentRef'
import { ChangeFnProps } from 'pages/product/versions/update/tabs/general/hooks/useHandleFileCodeUpload'
import { Flex } from 'ui-base/flex/Flex'

interface Props
  extends Omit<ComponentProps<typeof WppFileUpload>, 'name' | 'onFocus' | 'onBlur' | 'onChange' | 'locales' | 'value'> {
  name: string
  labelConfig?: LabelConfig
  required?: boolean
  className?: string
  fileUploadClassName?: string
  handleFileChange?: (args: ChangeFnProps) => void
  'data-testid'?: string
}

export const FormFileUpload = forwardRef<HTMLWppFileUploadElement, PropsWithChildren<Props>>(
  (
    {
      name,
      labelConfig,
      required,
      className,
      fileUploadClassName,
      id,
      message,
      messageType,
      onWppBlur,
      handleFileChange,
      'data-testid': dataTestId,
      children,
      ...rest
    },
    ref,
  ) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
      fieldState: { error, isTouched },
    } = useField({
      name,
    })
    const { t } = useTranslation(['common'])
    const identifier = id || name
    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText

    const fileUploadRef = useWebComponentRef<HTMLWppFileUploadElement, HTMLInputElement>({
      fieldRef,
      getInteractiveElement: el => el.shadowRoot?.querySelector('input.file-loader')!,
    })

    const locales = useMemo<FileUploadLocales>(
      () => ({
        label: t('common|form_file_upload.label'),
        text: t('common|form_file_upload.text'),
        info: (accept, size) => t('common|form_file_upload.info', { accept, size }),
        sizeError: t('common|form_file_upload.size_error'),
        formatError: t('common|form_file_upload.format_error'),
      }),
      [t],
    )

    const handleOnChange = (event: WppFileUploadCustomEvent<FileUploadEventDetail>) => {
      if (handleFileChange) {
        handleFileChange({
          eventDetail: event.detail,
          handleChange: onChange,
        })
      } else {
        onChange(event.detail.value)
      }
    }

    return (
      <Flex direction="column" gap={8} className={className}>
        {labelConfig?.text && <WppLabel optional={!required} config={labelConfig} />}

        {children}

        <WppFileUpload
          ref={mergeRefs([ref, fileUploadRef])}
          name={name}
          value={value}
          onWppChange={handleOnChange}
          locales={locales}
          onWppBlur={e => {
            onBlur()
            onWppBlur?.(e)
          }}
          id={identifier}
          messageType={shouldShowError ? 'error' : messageType}
          message={shouldShowError ? errorText : message}
          data-testid={dataTestId}
          className={fileUploadClassName}
          {...rest}
        />
      </Flex>
    )
  },
)
