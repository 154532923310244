import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const AccessIconSvg = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="50.5" cy="50" r="50" className="fillPrimary200" />
    <path
      d="M70.3519 27.7965C62.2051 20.4998 54.9238 23.0694 51.8539 25.5017C49.8137 27.1453 48.737 29.0674 48.4537 29.823C48.0759 30.8148 47.3769 33.691 47.6036 37.2614C47.8303 40.8318 47.2258 42.244 46.8952 42.5037L44.7697 44.5581L46.6118 46.2583L26.9884 65.3857C25.7919 66.5471 25.7211 68.0348 26.5633 68.7153L30.0346 72.0449C31.1681 73.1217 32.4905 72.4935 33.01 72.0449L36.9063 76.1537C39.5274 74.7856 39.2475 75.7065 39.9525 73.0366C39.1024 72.5266 39.3149 71.974 39.5274 71.7615L40.6609 70.4155C41.681 69.0553 42.3139 69.4237 42.5028 69.7779C46.1191 67.7722 46.8984 66.7804 49.1619 64.2522L44.7697 60.0017L52.9166 52.5633L54.4043 53.9093C55.8778 51.0756 59.316 50.9811 60.8509 51.2881C69.692 52.0249 73.9331 47.1084 74.9485 44.5581C78.1789 37.7006 73.1856 30.4177 70.3519 27.7965Z"
      className="fillPrimary400"
    />
    <rect
      x="52.7949"
      y="32.1809"
      width="9.20329"
      height="18.1676"
      rx="4.60165"
      transform="rotate(-44.9169 52.7949 32.1809)"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.8278 72.0071C32.8311 72.103 32.8538 72.1437 32.9233 72.1078L32.8278 72.0071ZM34.1706 73.2683L36.9067 76.1535C38.3095 75.4213 38.8813 75.3448 39.2179 75.0225C39.5103 74.7426 39.6252 74.2774 39.9528 73.0365C39.1027 72.5264 39.3153 71.9739 39.5278 71.7613L40.6613 70.4153C41.6814 69.0552 42.3142 69.4235 42.5032 69.7778C45.9205 67.8824 46.8044 66.8925 48.8015 64.6558C48.9177 64.5256 49.0378 64.3912 49.1623 64.2521L46.2841 61.4667C46.1751 61.5885 46.0695 61.7067 45.9669 61.8216C43.9699 64.0582 43.086 65.0482 39.6686 66.9435C39.4797 66.5893 38.8469 66.2209 37.8267 67.5811L36.6933 68.9271C36.4808 69.1396 36.2682 69.6922 37.1183 70.2023C36.7906 71.4432 36.6757 71.9084 36.3834 72.1883C36.0548 72.5029 35.502 72.5833 34.1706 73.2683ZM44.8186 59.9573L41.9356 57.1673L50.0824 49.7289L51.5701 51.0749C53.0436 48.2412 56.4818 48.1468 58.0167 48.4537C66.8578 49.1905 71.0989 44.2741 72.1143 41.7237C74.7486 36.1317 71.9143 30.2566 69.2419 26.8574C69.61 27.1505 69.9802 27.4631 70.3523 27.7963C73.186 30.4175 78.1792 37.7005 74.9488 44.558C73.9334 47.1083 69.6923 52.0247 60.8513 51.288C59.3163 50.981 55.8781 51.0754 54.4046 53.9091L52.9169 52.5631L44.8186 59.9573Z"
      className="fillPrimary500"
    />
    <rect
      x="55.5461"
      y="32.239"
      width="5.38822"
      height="14.0267"
      rx="2.69411"
      transform="rotate(-44.9169 55.5461 32.239)"
      className="fillPrimary200"
    />
    <rect
      x="42.4359"
      y="45.238"
      width="4.40471"
      height="16.3249"
      rx="2.20235"
      transform="rotate(-44.9169 42.4359 45.238)"
      className="fillPrimary500"
    />
    <path
      d="M44.9154 59.9089L32.8722 71.9521L36.6269 75.9192C38.3271 77.9028 40.169 77.0527 40.8774 76.2735C41.4441 75.4233 41.2316 74.0065 40.2398 73.2272C39.283 72.4755 39.6967 71.8576 39.9564 71.527C40.2129 71.2006 40.4474 70.9077 40.6691 70.6549C41.4932 69.715 42.7038 70.3262 43.8528 70.8186C48.8117 72.1646 51.0078 66.3556 49.5201 64.5845L44.9154 59.9089Z"
      className="fillPrimary300"
    />
    <path
      d="M44.9154 59.9089L32.8722 71.9521L36.6269 75.9192C38.3271 77.9028 40.169 77.0527 40.8774 76.2735C41.4441 75.4233 41.2316 74.0065 40.2398 73.2272C39.283 72.4755 39.6967 71.8576 39.9564 71.527C40.2129 71.2006 40.4474 70.9077 40.6691 70.6549C41.4932 69.715 42.7038 70.3262 43.8528 70.8186C48.8117 72.1646 51.0078 66.3556 49.5201 64.5845L44.9154 59.9089Z"
      className="fillPrimary500"
    />
    <path
      d="M44.9143 59.9089L42.5056 62.3176L49.0231 69.1184C50.44 67.1915 50.3691 65.5763 49.6607 64.6554L44.9143 59.9089Z"
      className="fillPrimary400"
    />
  </S.CustomSvg>
)
