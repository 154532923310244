import { WppBanner } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'

import { useIsActionPermitted } from 'hooks/useIsActionPermitted'
import { AccessActions } from 'types/permissions/enums'
import { RoutesManager } from 'utils/routesManager'

export const ViewOnlyBanner = () => {
  const { t } = useTranslation(['products'])
  const { pathname } = useLocation()
  const { isActionPermitted } = useIsActionPermitted()
  const isDetailsManagePermitted = isActionPermitted([AccessActions.PRODUCT_MANAGE])
  const isVersionsManagePermitted = isActionPermitted([AccessActions.VERSION_MANAGE])
  //TODO: replace with new PROFILE_MANAGE permission when it will be introduced
  const isProfilesManagePermitted = isActionPermitted([AccessActions.VERSION_MANAGE])

  const isProductDetailsPage = matchPath(RoutesManager.products.details.pattern, pathname)?.params?.['*'] === ''
  const isVersionsPage = !!matchPath(RoutesManager.products.versions.root.pattern, pathname)?.pattern.path
  const isProfilesPage = !!matchPath(RoutesManager.products.profiles.root.pattern, pathname)?.pattern.path

  const showDetailsPageBanner = isProductDetailsPage && !isDetailsManagePermitted
  const showVersionsPageBanner = isVersionsPage && !isVersionsManagePermitted
  const showProfilesPageBanner = isProfilesPage && !isProfilesManagePermitted

  return (
    <WppBanner type="information" show={showDetailsPageBanner || showVersionsPageBanner || showProfilesPageBanner}>
      {t('products|view_only_banner')}
    </WppBanner>
  )
}
