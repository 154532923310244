import { WppActionButton } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes } from 'react'

import * as S from 'ui-base/errorState/ErrorState.styled'

interface Props extends HTMLAttributes<HTMLDivElement> {
  image: JSX.Element
  title: string
  handleClick: () => void
  buttonText: string
  margin?: string
  fullHeight?: boolean
}

export const ErrorStateContent = ({
  image,
  title,
  handleClick,
  buttonText,
  margin,
  fullHeight = true,
  ...rest
}: Props) => (
  <S.ErrorStateContent
    direction="column"
    align="center"
    justify="center"
    margin={margin}
    fullHeight={fullHeight}
    {...rest}
  >
    <S.PageImage>{image}</S.PageImage>
    <S.PageTitle type="xl-heading" tag="h1">
      {title}
    </S.PageTitle>
    <WppActionButton onClick={handleClick}>{buttonText}</WppActionButton>
  </S.ErrorStateContent>
)
