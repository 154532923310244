import { WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routesManager'

import * as S from 'pages/widget/components/WidgetHeader/WidgetBackButton/WidgetBackButton.styled'

export const WidgetBackButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['widgets'])

  const handleClick = () => {
    navigate({
      pathname: RoutesManager.myWidgets.root.getURL(),
    })
  }

  return (
    <S.BackWrapper onClick={handleClick} align="center" gap={4} data-testid="back-button">
      <WppIconChevron direction="left" />
      <WppTypography type="s-body">{t('widgets|breadcrumbs.details')}</WppTypography>
    </S.BackWrapper>
  )
}
