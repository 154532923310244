import { MayBeNull } from '@wpp-open/core'
import { createContext } from 'react'

import { TipId } from 'types/tipCard/enums'
import { TipCardState } from 'types/tipCard/tipCard'

export interface TipCardValue {
  state: TipCardState
  isLoading: boolean
  isUpdating: boolean
  handleClose: MayBeNull<(id: TipId) => void>
}

export const TipCardContext = createContext<TipCardValue>({
  state: {} as TipCardState,
  isLoading: false,
  isUpdating: false,
  handleClose: null,
})
