import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const OopsIconSvg = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="50" cy="50" r="50" className="fillPrimary200" />
    <path
      d="M33.7955 50.2728C33.7955 52.1932 33.4262 53.8211 32.6875 55.1563C31.9489 56.4915 30.9489 57.5057 29.6875 58.1989C28.4318 58.8921 27.0227 59.2387 25.4602 59.2387C23.8921 59.2387 22.4801 58.8893 21.2245 58.1904C19.9688 57.4915 18.9716 56.4773 18.233 55.1478C17.5 53.8126 17.1335 52.1876 17.1335 50.2728C17.1335 48.3523 17.5 46.7245 18.233 45.3893C18.9716 44.054 19.9688 43.0398 21.2245 42.3467C22.4801 41.6535 23.8921 41.3069 25.4602 41.3069C27.0227 41.3069 28.4318 41.6535 29.6875 42.3467C30.9489 43.0398 31.9489 44.054 32.6875 45.3893C33.4262 46.7245 33.7955 48.3523 33.7955 50.2728ZM29.483 50.2728C29.483 49.1364 29.321 48.1762 28.9972 47.3921C28.679 46.608 28.2188 46.0143 27.6165 45.6109C27.0199 45.2075 26.3012 45.0057 25.4602 45.0057C24.625 45.0057 23.9063 45.2075 23.304 45.6109C22.7017 46.0143 22.2387 46.608 21.9148 47.3921C21.5966 48.1762 21.4375 49.1364 21.4375 50.2728C21.4375 51.4092 21.5966 52.3694 21.9148 53.1535C22.2387 53.9376 22.7017 54.5313 23.304 54.9347C23.9063 55.3381 24.625 55.5398 25.4602 55.5398C26.3012 55.5398 27.0199 55.3381 27.6165 54.9347C28.2188 54.5313 28.679 53.9376 28.9972 53.1535C29.321 52.3694 29.483 51.4092 29.483 50.2728Z"
      className="fillPrimary500"
    />
    <path
      d="M52.733 50.2728C52.733 52.1932 52.3637 53.8211 51.625 55.1563C50.8864 56.4915 49.8864 57.5057 48.625 58.1989C47.3693 58.8921 45.9603 59.2387 44.3978 59.2387C42.8296 59.2387 41.4176 58.8893 40.162 58.1904C38.9063 57.4915 37.9091 56.4773 37.1705 55.1478C36.4375 53.8126 36.071 52.1876 36.071 50.2728C36.071 48.3523 36.4375 46.7245 37.1705 45.3893C37.9091 44.054 38.9063 43.0398 40.162 42.3467C41.4176 41.6535 42.8296 41.3069 44.3978 41.3069C45.9603 41.3069 47.3693 41.6535 48.625 42.3467C49.8864 43.0398 50.8864 44.054 51.625 45.3893C52.3637 46.7245 52.733 48.3523 52.733 50.2728ZM48.4205 50.2728C48.4205 49.1364 48.2585 48.1762 47.9347 47.3921C47.6165 46.608 47.1563 46.0143 46.554 45.6109C45.9574 45.2075 45.2387 45.0057 44.3978 45.0057C43.5625 45.0057 42.8438 45.2075 42.2415 45.6109C41.6392 46.0143 41.1762 46.608 40.8523 47.3921C40.5341 48.1762 40.375 49.1364 40.375 50.2728C40.375 51.4092 40.5341 52.3694 40.8523 53.1535C41.1762 53.9376 41.6392 54.5313 42.2415 54.9347C42.8438 55.3381 43.5625 55.5398 44.3978 55.5398C45.2387 55.5398 45.9574 55.3381 46.554 54.9347C47.1563 54.5313 47.6165 53.9376 47.9347 53.1535C48.2585 52.3694 48.4205 51.4092 48.4205 50.2728Z"
      className="fillPrimary500"
    />
    <path
      d="M55.196 59.0001V41.5455H62.4063C63.7131 41.5455 64.8409 41.8012 65.7898 42.3126C66.7443 42.8182 67.4801 43.5256 67.9972 44.4347C68.5142 45.3381 68.7728 46.3893 68.7728 47.5881C68.7728 48.7927 68.5085 49.8467 67.9801 50.7501C67.4574 51.6478 66.7103 52.3438 65.7387 52.8381C64.7671 53.3325 63.6137 53.5796 62.2784 53.5796H57.8296V50.2557H61.4943C62.1307 50.2557 62.662 50.145 63.0881 49.9234C63.5199 49.7018 63.8466 49.3921 64.0682 48.9944C64.2898 48.591 64.4006 48.1222 64.4006 47.5881C64.4006 47.0484 64.2898 46.5825 64.0682 46.1904C63.8466 45.7927 63.5199 45.4859 63.0881 45.27C62.6563 45.054 62.125 44.9461 61.4943 44.9461H59.4148V59.0001H55.196Z"
      className="fillPrimary500"
    />
    <path
      d="M80.287 46.7785C80.2301 46.1535 79.9773 45.6677 79.5284 45.3211C79.0853 44.9688 78.4517 44.7927 77.6279 44.7927C77.0824 44.7927 76.6279 44.8637 76.2642 45.0057C75.9006 45.1478 75.6279 45.3438 75.446 45.5938C75.2642 45.8381 75.1705 46.1194 75.1648 46.4376C75.1534 46.6989 75.2046 46.929 75.3182 47.1279C75.4375 47.3268 75.608 47.5029 75.8296 47.6563C76.0568 47.804 76.3296 47.9347 76.6478 48.0484C76.9659 48.162 77.3239 48.2614 77.7216 48.3467L79.2216 48.6876C80.0853 48.8751 80.8466 49.1251 81.5057 49.4376C82.1705 49.7501 82.7273 50.1222 83.1762 50.554C83.6307 50.9859 83.9745 51.483 84.2074 52.0455C84.4404 52.608 84.5597 53.2387 84.5654 53.9376C84.5597 55.0398 84.2813 55.9859 83.7301 56.7756C83.179 57.5654 82.3864 58.1705 81.3523 58.591C80.3239 59.0114 79.0824 59.2217 77.6279 59.2217C76.1676 59.2217 74.8949 59.0029 73.8097 58.5654C72.7245 58.1279 71.8807 57.4631 71.2784 56.5711C70.6762 55.679 70.3665 54.5512 70.3495 53.1876H74.3892C74.4233 53.7501 74.5739 54.2188 74.8409 54.5938C75.108 54.9688 75.4745 55.2529 75.9404 55.4461C76.412 55.6393 76.9574 55.7359 77.5767 55.7359C78.1449 55.7359 78.6279 55.6592 79.0256 55.5057C79.429 55.3523 79.7387 55.1393 79.9546 54.8665C80.1705 54.5938 80.2813 54.2813 80.287 53.929C80.2813 53.5995 80.179 53.3182 79.9801 53.0853C79.7813 52.8467 79.4745 52.6421 79.0597 52.4717C78.6506 52.2955 78.1279 52.1336 77.4915 51.9859L75.6676 51.5597C74.1563 51.2131 72.9659 50.6535 72.0966 49.8807C71.2273 49.1023 70.7955 48.0512 70.8012 46.7273C70.7955 45.6478 71.0853 44.7018 71.6705 43.8893C72.2557 43.0768 73.0654 42.4432 74.0995 41.9887C75.1335 41.5342 76.3125 41.3069 77.6364 41.3069C78.9887 41.3069 80.162 41.537 81.1563 41.9972C82.1563 42.4518 82.9318 43.091 83.483 43.9148C84.0341 44.7387 84.3154 45.6932 84.3267 46.7785H80.287Z"
      className="fillPrimary500"
    />
  </S.CustomSvg>
)
