import { ToastState } from '@platform-ui-kit/components-library'
import { useRef, useEffect } from 'react'

export const useToast = () => {
  const toastRef = useRef<HTMLWppToastContainerElement | null>()

  useEffect(() => {
    toastRef.current = document.querySelector('.wpp-toast-container') as HTMLWppToastContainerElement
  }, [])

  const showToast = ({ duration = 4000, ...config }: ToastState) => toastRef.current?.addToast({ duration, ...config })!
  const hideToast = (id: string) => toastRef.current?.hideToast(id)
  const updateToast = (id: string, updatedData: Partial<Omit<ToastState, 'duration'>>) =>
    toastRef.current?.updateToast(id, updatedData)

  return { showToast, hideToast, updateToast }
}
