import { styled } from '@mui/material/styles'
import { WppTypography } from '@platform-ui-kit/components-library-react'

export const StyledIcon = styled('svg')<{ size?: number }>`
  width: ${({ size }) => (size ? `${size}px` : 'unset')};
  height: ${({ size }) => (size ? `${size}px` : 'unset')};
  flex-shrink: 0;
`

export const Label = styled(WppTypography)`
  &::part(typography) {
    color: var(--wpp-grey-color-800);
  }
`
