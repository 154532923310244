import imageCompression from 'browser-image-compression'

import { useFileUpload } from 'hooks/useFileUpload'
import { API } from 'types/common/enums'
import { NativeWidgetFormDTO } from 'types/widgets/widget'

export const useUploadWidgetCoverImageThumbnail = (coverImage: NativeWidgetFormDTO['logoMeta'][0]) => {
  const file = coverImage?.file
  const { handleUploadFiles } = useFileUpload({
    api: API.DEVHUB,
    acceptGroup: 'image',
  })
  return async () => {
    if (!file && coverImage?.thumbnail) {
      return coverImage.thumbnail
    }

    try {
      const thumbnailFile = await imageCompression(file!, {
        maxSizeMB: file!.size / 2,
        useWebWorker: true,
      })

      const uploadedThumbnailFile = await handleUploadFiles({
        file: thumbnailFile,
        id: thumbnailFile.name,
      })
      return uploadedThumbnailFile.attachment
    } catch (error) {
      console.error('Error compressing or uploading file:', error)
    }
  }
}
