import { styled } from '@mui/material/styles'
import { WppGrid } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const Wrapper = styled(Flex)`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: var(--wpp-os-content-max-width, auto);
  min-height: calc(100vh - var(--wpp-os-header-height) - var(--wpp-devhub-header-height));
  padding-top: 8px;
  margin-top: -8px;
`

export const GridContainer = styled(WppGrid)`
  height: inherit;
`

export const GridItem = styled(WppGrid)`
  flex-grow: 1;
`
