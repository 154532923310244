import { useQueryClient } from '@tanstack/react-query'

import { createUseMutation } from 'api/common/createUseMutation'
import { updateWidgetApi } from 'api/widgets/fetchers/updateWidgetApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useUpdateWidgetApi = createUseMutation({
  fetcher: updateWidgetApi,
})

export const useUpdateWidgetApiWithEffects = () => {
  const queryClient = useQueryClient()
  return useUpdateWidgetApi({
    onSuccess: response => {
      queryClient.setQueryData([ApiQueryKeys.WIDGET, { widgetId: response.data.id }], response)
      queryClient.invalidateQueries([ApiQueryKeys.WIDGETS_INFINITE])
    },
  })
}
