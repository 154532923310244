import { PropsWithChildren, useMemo } from 'react'

import featureFlagsConfig, { FEATURE_TYPES, featureFlags } from 'providers/featureFlags/featureFlagsConfig'
import { FeatureFlagsContext } from 'providers/featureFlags/FeatureFlagsContext'

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const flags: { [key in FEATURE_TYPES]: boolean } = useMemo(
    () =>
      Object.fromEntries(
        (Object.keys(featureFlags) as FEATURE_TYPES[]).map(key => [key, featureFlagsConfig[key] === 'true']),
      ) as { [key in FEATURE_TYPES]: boolean },
    [],
  )

  return (
    <FeatureFlagsContext.Provider
      value={{
        flags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  )
}
