import { WppGrid } from '@platform-ui-kit/components-library-react'

import { WidgetHeader } from 'pages/widget/components/WidgetHeader/WidgetHeader'
import { NativeWidgetUpdateForm } from 'pages/widget/update/nativeWidget/components/nativeWidgetUpdateForm/NativeWidgetUpdateForm'
import { WidgetAvailability } from 'pages/widget/update/nativeWidget/components/WidgetAvailability/WidgetAvailability'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/widget/update/WidgetDetailsPage.styled'

export const WidgetDetailsPage = () => (
  <S.PageWrapper direction="column" gap={24}>
    <WidgetHeader />
    <Flex gap={28}>
      <WppGrid container fullWidth>
        <WppGrid item all={14}>
          <NativeWidgetUpdateForm />
        </WppGrid>
        <WppGrid item all={10}>
          <WidgetAvailability />
        </WppGrid>
      </WppGrid>
    </Flex>
  </S.PageWrapper>
)
