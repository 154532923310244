import { LabelConfig, PillValue } from '@platform-ui-kit/components-library'
import {
  WppInlineMessage,
  WppLabel,
  WppPill,
  WppPillGroup,
  WppPopover,
} from '@platform-ui-kit/components-library-react'
import { ComponentProps, useCallback } from 'react'

import { useField } from 'hooks/form/useField'
import { Flex } from 'ui-base/flex/Flex'
import { GridSize, GridSizeSelect } from 'ui-base/gridSizeSelect/GridSizeSelect'

interface Props extends Omit<ComponentProps<typeof WppPopover>, 'onChange'> {
  name: string
  buttonTitle: string
  maxGridOption?: number
  required?: boolean
  disabled?: boolean
  dataTestId?: string
  labelConfig?: LabelConfig
  onChange?: (newState: GridSize) => void
}

export function FormGridSizeSelect({
  buttonTitle,
  maxGridOption = 6,
  name,
  required,
  disabled,
  labelConfig,
  dataTestId,
}: Props) {
  const {
    field: { ref: fieldRef, value, onChange },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })
  const fieldValue: GridSize[] = value

  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText

  const onChangeHandler = useCallback(
    (size: GridSize) => {
      const isNoDuplicate = !fieldValue.find(value => value.width === size.width && value.height === size.height)

      if (isNoDuplicate) {
        onChange([...fieldValue, size])
      }
    },
    [onChange, fieldValue],
  )

  const onDeleteHandler = (deleteValue: GridSize) => {
    const newValue = fieldValue.filter(
      (size: GridSize) => !(size.width === deleteValue.width && size.height === deleteValue.height),
    )
    onChange(newValue)
  }

  return (
    <Flex direction="column" gap={4} data-testid={dataTestId}>
      <Flex direction="column" gap={8}>
        {labelConfig?.text && <WppLabel optional={!required} config={labelConfig} />}
        <Flex direction="row" gap={8}>
          <GridSizeSelect
            buttonTitle={buttonTitle}
            maxGridOption={maxGridOption}
            onChange={onChangeHandler}
            disabled={disabled}
            ref={fieldRef}
          />
          <WppPillGroup type="display">
            {fieldValue &&
              fieldValue.map((size, index) => (
                <WppPill
                  type="display"
                  label={`${size.width} x ${size.height}`}
                  key={`${size.width} x ${size.height}`}
                  value={size as unknown as PillValue}
                  removable
                  onWppClose={() => onDeleteHandler(size)}
                  data-testid={`${dataTestId}-pill-${index}`}
                />
              ))}
          </WppPillGroup>
        </Flex>
      </Flex>
      {shouldShowError && <WppInlineMessage message={errorText} type="error" />}
    </Flex>
  )
}
