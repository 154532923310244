import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { AppPermissions } from 'types/permissions/enums'

export type PermissionsArg = AppPermissions | AppPermissions[]

const checkPermissions = (permissions: string[], requiredPermissions?: PermissionsArg, requireAll?: boolean) => {
  const permissionsToCheck = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]

  if (!permissions.length) return false
  if (!permissionsToCheck.length) return true
  if (requireAll) return permissionsToCheck?.every(permission => permissions.includes(permission!))

  return permissionsToCheck?.some(permission => permissions.includes(permission!))
}

export const useIsPermitted = () => {
  const {
    osContext: { permissions: allPermissions, tenant },
  } = useOs()

  const organizationPermissions = useMemo(
    () => allPermissions.find(({ account_id }) => account_id === tenant.azMeta.organizationsId)?.permissions || [],
    [allPermissions, tenant.azMeta.organizationsId],
  )

  const isPermitted = useCallback(
    (requiredPermissions?: PermissionsArg, requireAll?: boolean) =>
      checkPermissions(organizationPermissions, requiredPermissions, requireAll),
    [organizationPermissions],
  )

  return {
    isPermitted,
  }
}
