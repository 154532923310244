import { AttachmentMeta, AttachmentsAcceptGroup } from 'types/common/attachments'
import { API } from 'types/common/enums'
import { getApiInstance } from 'utils/api'

interface Params {
  api: API
  url: string
  file: File
  acceptGroup: AttachmentsAcceptGroup
  signal?: AbortSignal
}

export const uploadToSignedUrlApi = ({ api, url, file, acceptGroup, signal }: Params) =>
  getApiInstance(api).put<AttachmentMeta>(url, file, {
    headers: { 'Content-Type': acceptGroup === 'file' ? 'application/octet-stream' : file.type },
    signal,
  })
