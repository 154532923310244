import {
  FileItemType,
  FileUploadEventDetail,
} from '@platform-ui-kit/components-library/dist/types/components/wpp-file-upload/types'
import { WppFileUploadCustomEvent } from '@platform-ui-kit/components-library/loader'
import { MayBeNull } from '@wpp-open/core'
import { useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFileUpload } from 'hooks/useFileUpload'
import { useToast } from 'hooks/useToast'
import { API } from 'types/common/enums'

export interface ChangeFnProps {
  eventDetail: WppFileUploadCustomEvent<FileUploadEventDetail>['detail']
  handleChange: (arg: any) => void
}

type UploadFileItemType = FileItemType & { key?: string; isErrorFile?: boolean; downloadable?: any; file?: File }

const getFileId = (file: MayBeNull<UploadFileItemType>) => (file ? `${file?.name}-${file?.size}` : null)
const mapFileObjToJSON = (file: FileItemType) => {
  return {
    type: file.type,
    name: file.name,
    size: file.size,
    downloadable: {},
  } as UploadFileItemType
}

export const useUploadWidgetCoverImage = () => {
  const { showToast } = useToast()
  const { t } = useTranslation(['errors'])
  const { handleUploadFiles, reset: resetUpload } = useFileUpload({
    api: API.DEVHUB,
    acceptGroup: 'file',
    generateDownloadable: true,
  })

  const prevFileRef = useRef<MayBeNull<UploadFileItemType>>(null)

  return useCallback(
    async ({ handleChange, eventDetail }: ChangeFnProps) => {
      try {
        const { value } = eventDetail
        const filteredValues = value?.filter((item: UploadFileItemType) => !item.isErrorFile)

        const currentFileValue = filteredValues?.[filteredValues?.length - 1]

        const currentFileId = getFileId(currentFileValue!)
        const prevFileId = getFileId(prevFileRef.current)
        const fileIsChanged = currentFileId !== prevFileId

        if (!currentFileValue) {
          if (prevFileId) resetUpload(prevFileId)
          prevFileRef.current = null
          handleChange([])
        }

        if (fileIsChanged && currentFileValue) {
          prevFileRef.current = mapFileObjToJSON(currentFileValue)
          handleChange([prevFileRef.current])

          if (prevFileId) resetUpload(prevFileId)
          const uploadedFile = await handleUploadFiles({
            file: currentFileValue as File,
            id: currentFileId!,
          })

          prevFileRef.current = {
            ...prevFileRef.current,
            key: uploadedFile.attachment.key,
            downloadable: uploadedFile.downloadable,
            file: currentFileValue as File,
          } as UploadFileItemType

          handleChange([prevFileRef.current])
        }
      } catch (err: any) {
        if (err?.message === 'canceled') {
          // not an error, file uploading was canceled
          return
        }
        // on practice this should never happen
        showToast({ message: t('errors|general'), type: 'error' })
      }
    },
    [handleUploadFiles, showToast, t, resetUpload],
  )
}
