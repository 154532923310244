import { styled } from '@mui/material/styles'
import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link as NavigationLink } from 'react-router-dom'

export const Card = styled(WppCard, {
  shouldForwardProp: props => props !== 'backgroundColor' && props !== 'backgroundOpacity',
})<{
  backgroundColor: string
  backgroundOpacity?: number
}>`
  &::part(card) {
    background: ${({ backgroundColor, backgroundOpacity }) =>
      `color-mix(in srgb, ${backgroundColor} ${backgroundOpacity || 100}%, transparent);`};
  }

  &::part(header-wrapper) {
    margin-bottom: 8px;
  }

  &::part(header-wrapper) {
    align-items: baseline;
    flex-wrap: wrap;
  }
`

export const Link = styled(NavigationLink)`
  display: inline-flex;
  margin-left: 3px;
  text-decoration: underline;
  color: var(--wpp-text-color);
`

export const Description = styled(WppTypography)`
  display: inline;
  max-width: 100%;
  &::part(typography) {
    display: inline;
    overflow: hidden;
    max-width: 100%;
    white-space: normal;
    word-break: keep-all;
  }
`
