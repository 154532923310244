import { tipIdValues, TipState } from 'types/tipCard/enums'
import { TipCardState, TipCardItem } from 'types/tipCard/tipCard'

// initially server might not return all the tipIds as it returns only those IDs which were previously saved,
// so we consider all Tips as 'OPENED' by default
export const parsePropTipData = (items: TipCardItem[]) =>
  ({
    ...Object.fromEntries(tipIdValues.map(item => [item, TipState.OPENED])),
    ...Object.fromEntries(items.map(item => [item.id, item.enabled ? TipState.OPENED : TipState.CLOSED])),
  } as TipCardState)
