import { LazyExoticComponent } from 'react'

import { BenefitIcon } from 'types/products/enums'
import { FormBenefit } from 'types/products/product'

export interface IconProps {
  name: string
  component: LazyExoticComponent<any>
}

export type GetIconFn = (name: IconProps['name'] | undefined) => IconProps['component']

export type EditBenefitFn = (currentBenefit: FormBenefit, benefitIndex: number) => void

export type SortBenefitsFn = (benefits: FormBenefit[]) => void

export const BenefitIcons = Object.values(BenefitIcon)

export const FallbackBenefitIcon = 'WppIconError' as const
