import { WppActionButton, WppIconPlus, WppPopover, WppTypography } from '@platform-ui-kit/components-library-react'
import { forwardRef, ComponentProps, Ref, ReactElement, useRef, useEffect, useState, useCallback } from 'react'

import { Flex } from 'ui-base/flex/Flex'

import * as S from 'ui-base/gridSizeSelect/GridSizeSelect.styled'

interface Props extends Omit<ComponentProps<typeof WppPopover>, 'onChange'> {
  disabled?: boolean
  buttonTitle: string
  maxGridOption: number
  onChange: (newState: GridSize) => void
}

const GRID_ITEM_WIDTH = 20
const GRID_ITEM_HEIGHT = 20
const GRID_ITEM_GAP = 4
const MODAL_PADDING = 8

const CLOSE_ANIMATION_DELAY = 300

export interface GridSize {
  width: number
  height: number
}

export const GridSizeSelect = forwardRef(({ onChange, buttonTitle, maxGridOption, disabled }: Props, ref: any) => {
  const [selectedItemPoints, setSelectedItemPoints] = useState([0, 0])
  const selectedItemSizeRef = useRef<GridSize>({ width: 0, height: 0 })

  const popoverRef = useRef<HTMLWppPopoverElement>(null)
  const gridWrapperRef = useRef<HTMLDivElement>(null)

  const handleClick = useCallback(() => {
    onChange(selectedItemSizeRef.current)
    popoverRef?.current?.closePopover()
    setTimeout(() => {
      setSelectedItemPoints([0, 0])
      selectedItemSizeRef.current = { width: 0, height: 0 }
    }, CLOSE_ANIMATION_DELAY)
  }, [onChange])

  const handleMove = useCallback(
    (e: MouseEvent) => {
      const gridRect = gridWrapperRef.current?.getBoundingClientRect()
      const mouseX = e.clientX - gridRect?.left! - MODAL_PADDING
      const mouseY = e.clientY - gridRect?.top! - MODAL_PADDING

      if (mouseX < 0 || mouseY < 0) {
        selectedItemSizeRef.current = { width: 0, height: 0 }
      }

      const column = Math.floor(mouseX / (GRID_ITEM_WIDTH + GRID_ITEM_GAP)) + 1
      const row = Math.floor(mouseY / (GRID_ITEM_HEIGHT + GRID_ITEM_GAP)) + 1
      const columnNormalized = column > maxGridOption ? maxGridOption : column
      const rowNormalized = row > maxGridOption ? maxGridOption : row

      const offsetX = columnNormalized * (GRID_ITEM_WIDTH + GRID_ITEM_GAP) - GRID_ITEM_GAP
      const offsetY = rowNormalized * (GRID_ITEM_HEIGHT + GRID_ITEM_GAP) - GRID_ITEM_GAP

      setSelectedItemPoints([offsetX, offsetY])
      selectedItemSizeRef.current = { width: columnNormalized, height: rowNormalized }
    },
    [maxGridOption],
  )

  useEffect(() => {
    const wrapperNode = gridWrapperRef.current

    if (wrapperNode) {
      wrapperNode.addEventListener('click', handleClick, { capture: false })
      wrapperNode.addEventListener('mousemove', handleMove, { capture: false })
    }
    return () => {
      if (wrapperNode) {
        wrapperNode.removeEventListener('click', handleClick)
        wrapperNode.removeEventListener('mousemove', handleMove)
      }
    }
  }, [handleClick, handleMove])

  return (
    <WppPopover config={{ appendTo: () => document.querySelector('#root')! }} ref={popoverRef}>
      <WppActionButton disabled={disabled} slot="trigger-element" ref={ref}>
        <WppIconPlus slot="icon-start" />
        {buttonTitle}
      </WppActionButton>
      <S.ContentWrapper data-testid="popover-content" ref={gridWrapperRef}>
        <Flex direction="column" gap={4}>
          <S.SelectedSquares width={selectedItemPoints[0]} height={selectedItemPoints[1]} ref={ref} />
          {Array.from({ length: maxGridOption }).map((_, x) => {
            return (
              <Flex direction="row" key={x} gap={4}>
                {Array.from({ length: maxGridOption }).map((_, y) => (
                  <S.Square key={`${x + 1}-${y + 1}`} data-testid={`option-${x + 1}-${y + 1}`} />
                ))}
              </Flex>
            )
          })}
          <Flex justify="center">
            <WppTypography>
              {selectedItemSizeRef.current.width} x {selectedItemSizeRef.current.height}
            </WppTypography>
          </Flex>
        </Flex>
      </S.ContentWrapper>
    </WppPopover>
  )
}) as (p: Props & { ref?: Ref<unknown> }) => ReactElement
