import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

import { theme } from 'providers/styles/Theme'

const cacheRtl = createCache({
  key: 'wpp',
  stylisPlugins: [
    // TODO: Enable/disable based on document dir
    // rtlPlugin as StylisPlugin
  ],
})

export const StylesProvider = ({ children }: PropsWithChildren) => (
  <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </CacheProvider>
)
