import { Global } from '@emotion/react'
import { FC } from 'react'

import { AppRoutes } from 'app/AppRoutes'
import { ApiProvider } from 'providers/api/ApiProvider'
import { FeatureFlagsProvider } from 'providers/featureFlags/FeatureFlagsProvider'
import { LocalisationContextValue } from 'providers/localisation/LocalisationContext'
import { LocalisationProvider } from 'providers/localisation/LocalisationProvider'
// TODO: should be removed after moving from styledComponents to css modules
import { StylesProvider } from 'providers/styles/StylesProvider'
import { TipCardProvider } from 'providers/tipCard/TipCardProvider'
import { GlobalStyles } from 'styles/GlobalStyles'

interface Props {
  locale?: LocalisationContextValue
}

export const App: FC<Props> = ({ locale }) => (
  <StylesProvider>
    <Global styles={GlobalStyles} />
    <ApiProvider>
      <FeatureFlagsProvider>
        <TipCardProvider>
          <LocalisationProvider locale={locale}>
            <AppRoutes />
          </LocalisationProvider>
        </TipCardProvider>
      </FeatureFlagsProvider>
    </ApiProvider>
  </StylesProvider>
)
