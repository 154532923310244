import { WppTooltip } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useRef, ComponentProps, ReactElement, useCallback } from 'react'

import * as S from 'ui-base/tooltip/Tooltip.styled'

interface Props extends Omit<ComponentProps<typeof WppTooltip>, 'text' | 'theme'> {
  show: boolean
  showOnOverflow?: boolean
  getInnerElement?: (el: MayBeNull<HTMLElement>) => MayBeNull<HTMLElement> | undefined
  text?: string
  children: ReactElement
}

export const Tooltip = ({ show, showOnOverflow, getInnerElement, text, config, children, ...rest }: Props) => {
  const childRef = useRef<HTMLDivElement>(null!)

  const onShow = useCallback(() => {
    if (!showOnOverflow) return // we don't have showOnOverflow prop, so we show tooltip without conditions
    const firstChild = childRef.current?.firstChild as HTMLElement
    const innerElement = getInnerElement?.(firstChild) || firstChild
    const hasOverflow = innerElement?.offsetWidth < innerElement?.scrollWidth
    const hasMultilineOverflow = innerElement?.scrollHeight > innerElement?.clientHeight
    if (!hasOverflow && !hasMultilineOverflow) {
      return false // do not show tooltip
    }
  }, [showOnOverflow, getInnerElement])

  if (!show || !text) return children as ReactElement

  return (
    <S.Tooltip
      config={{
        onShow,
        placement: 'right',
        appendTo: () => document.body,
        delay: 75, // start showing tooltip after (ms)
        ...config,
      }}
      text={text}
      {...rest}
    >
      {showOnOverflow ? (
        <S.ChildWrap ref={childRef} className="childWrap">
          {children}
        </S.ChildWrap>
      ) : (
        children
      )}
    </S.Tooltip>
  )
}
