import { WppTextareaInput } from '@platform-ui-kit/components-library-react'
import { forwardRef, ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'
import { useWebComponentRef } from 'hooks/form/useWebComponentRef'

interface Props
  extends Omit<
    ComponentProps<typeof WppTextareaInput>,
    'name' | 'value' | 'onChange' | 'onBlur' | 'onWppBlur' | 'onWppChange'
  > {
  name: string
}

export const FormTextArea = forwardRef<HTMLWppTextareaInputElement, Props>(
  ({ id, name, labelConfig, message, messageType, ...rest }, ref) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
      fieldState: { error, isTouched },
    } = useField({
      name,
    })
    const { t } = useTranslation()

    const identifier = id || name
    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText

    const textAreaRef = useWebComponentRef<HTMLWppTextareaInputElement, HTMLTextAreaElement>({
      fieldRef,
      getInteractiveElement: el => el.shadowRoot?.querySelector('textarea')!,
    })

    return (
      <WppTextareaInput
        {...rest}
        ref={mergeRefs([ref, textAreaRef])}
        id={identifier}
        name={name}
        {...(labelConfig && {
          labelConfig: {
            ...labelConfig,
            locales: { optional: t('optional') },
          },
        })}
        value={value}
        onWppChange={({ detail }) => {
          onChange(detail?.value)
        }}
        onWppBlur={onBlur}
        message={shouldShowError ? errorText : message}
        messageType={shouldShowError ? 'error' : messageType}
        warningThreshold={rest.charactersLimit}
        locales={{ charactersEntered: t('characters') }}
      />
    )
  },
)
