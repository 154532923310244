import { useCallback } from 'react'

import { useAppData } from 'hooks//useAppData'
import { AccessActions } from 'types/permissions/enums'

/**
 * Hook returns a callback which checks are every of requiredActions presented in productAccessAction
 * or in product data in case of details page
 *
 * @param accessAction
 */
export const useIsActionPermitted = (accessAction?: AccessActions[]) => {
  const { product } = useAppData()
  const actionsSource = accessAction || product?.accessActions

  const isActionPermitted = useCallback(
    (requiredActions?: AccessActions[]) =>
      requiredActions?.every(requiredAction => !!actionsSource?.find(action => action === requiredAction)),
    [actionsSource],
  )

  return {
    isActionPermitted,
  }
}
