import dayjs from 'dayjs'
import { PropsWithChildren, useEffect } from 'react'

import i18n, { createi18n } from 'lib/i18n'
import { LocalisationContext, LocalisationContextValue } from 'providers/localisation/LocalisationContext'

createi18n()

const defaultLocale = {
  dateLocale: 'en',
  displayLanguage: 'en',
  numberLocale: 'en',
}

export const LocalisationProvider = ({
  children,
  locale,
  testI18nInstance, // optional prop to inject test i18n instance
}: PropsWithChildren<{ locale?: LocalisationContextValue; testI18nInstance?: typeof i18n }>) => {
  const appliedI18n = testI18nInstance || i18n
  useEffect(() => {
    ;(async () => {
      !testI18nInstance && (await appliedI18n.changeLanguage(locale?.displayLanguage || defaultLocale.displayLanguage))
    })()
    dayjs.locale(locale?.dateLocale || defaultLocale.dateLocale)
  }, [locale, appliedI18n, testI18nInstance])

  return (
    <LocalisationContext.Provider
      value={{
        dateLocale: locale?.dateLocale || defaultLocale.dateLocale,
        displayLanguage: locale?.displayLanguage || defaultLocale.displayLanguage,
        numberLocale: locale?.numberLocale || defaultLocale.numberLocale,
      }}
    >
      {children}
    </LocalisationContext.Provider>
  )
}
