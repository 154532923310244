import { forwardRef, SVGProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { WidgetType, WidgetTypeLabel } from 'types/widgets/enums'
import { Flex } from 'ui-base/flex/Flex'
import { ReactComponent as NativeAppIcon } from 'ui-base/svgIcons/widgetTypeIcon/assets/nativeWidgetIcon.svg'

import * as S from 'ui-base/svgIcons/widgetTypeIcon/WidgetTypeIcon.styled'

interface Props extends SVGProps<SVGSVGElement> {
  widgetType: WidgetType
  size?: number
  withLabel?: boolean
}

export const WidgetTypeIcon = forwardRef<SVGSVGElement, Props>(
  ({ size, withLabel, widgetType, ...rest }: Props, ref) => {
    const { t } = useTranslation(['widgets'])
    const IconComponent = useMemo(
      () => ({
        [WidgetType.NATIVE]: NativeAppIcon,
      }),
      [],
    )[widgetType]

    if (!withLabel) {
      return <S.StyledIcon as={IconComponent} size={size} ref={ref} {...rest} data-testid="widget-type" />
    }

    return (
      <Flex gap={6} data-testid="widget-type">
        <S.StyledIcon as={IconComponent} size={size} ref={ref} {...rest} />
        <S.Label type="2xs-strong">{t(`widgets|type.${WidgetTypeLabel[widgetType]}.name`)}</S.Label>
      </Flex>
    )
  },
)
