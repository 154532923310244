import { AttachmentUploadMeta } from 'types/common/attachments'
import { API } from 'types/common/enums'
import { getApiInstance } from 'utils/api'

interface Params {
  api: API
  names: string[]
  signal?: AbortSignal
}

export const getUploadUrlsApi = ({ api, names, signal }: Params) =>
  getApiInstance(api).post<AttachmentUploadMeta[]>('/object-storage/temp/generate-upload-urls', { names, signal })
