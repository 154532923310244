import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorStateContent } from 'ui-base/errorState/components/ErrorStateContent'
import { CrossIconSvg } from 'ui-base/svgIcons/errorState/assets/CrossIconSvg'
import { BaseErrorBoundary } from 'ui-shared/errorBoundaries/common/BaseErrorBoundary'

import * as S from 'ui-shared/errorBoundaries/global/GlobalErrorBoundary.styled'

export const GlobalErrorBoundary = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation(['errors', 'common'])

  return (
    <BaseErrorBoundary
      fallback={
        <S.PageContainer direction="column" align="center" justify="center">
          <ErrorStateContent
            title={t('errors|global_error')}
            image={<CrossIconSvg />}
            handleClick={() => window.location.reload()}
            buttonText={t('errors|reload_page')}
          />
        </S.PageContainer>
      }
    >
      {children}
    </BaseErrorBoundary>
  )
}
