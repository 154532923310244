import { WppCard, WppToggle, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useActivateWidgetAvailability } from 'api/widgets/mutations/useActivateWidgetAvailability'
import { useDeactivateWidgetAvailability } from 'api/widgets/mutations/useDeactivateWidgetAvailability'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { useWidgetData } from 'pages/widget/hooks/useWidgetData'
import { Flex } from 'ui-base/flex/Flex'
import { InfoMessage } from 'ui-shared/infoMessage/InfoMessage'
import { unpackApiError } from 'utils/form'

export const WidgetAvailability = () => {
  const { t } = useTranslation(['widgets'])
  const { showToast } = useToast()
  const {
    osContext: { tenant },
  } = useOs()
  const queryClient = useQueryClient()
  const { mutateAsync: activateWidgetAvailability } = useActivateWidgetAvailability()
  const { mutateAsync: deactivateWidgetAvailability } = useDeactivateWidgetAvailability()

  const { widget } = useWidgetData()
  const isFormFilled = !!(widget?.name && !!widget?.description && widget.bundleUrl && widget.widgetSizes.length > 0)
  const isAvailabilityDisabled = !isFormFilled

  const toggleCurrentTenantAvailability = useCallback(async () => {
    try {
      if (widget?.tenantAvailability) {
        await deactivateWidgetAvailability({ widgetId: widget.id })
      } else {
        await activateWidgetAvailability({ widgetId: widget!.id })
      }
      await Promise.all([
        queryClient.invalidateQueries([ApiQueryKeys.WIDGET]),
        queryClient.invalidateQueries([ApiQueryKeys.WIDGETS_INFINITE]),
      ])

      showToast({
        message: t('widgets|availability.notifications.success_message_change_access'),
        type: 'success',
      })
    } catch (apiError) {
      const { detail } = unpackApiError(apiError)
      const defaultError = t('widgets|availability.notifications.failure_message_change_access')

      showToast({
        message: detail || defaultError,
        type: 'error',
      })
    }
  }, [widget, queryClient, showToast, t, deactivateWidgetAvailability, activateWidgetAvailability])
  return (
    <WppCard size="xl">
      <div slot="header">
        <WppTypography type="xl-heading">{t('widgets|availability.title')}</WppTypography>
      </div>
      <Flex direction="column" gap={24}>
        {/*TODO: We are missing permission for now*/}
        <WppToggle
          labelConfig={{ text: t('widgets|availability.toggle_label', { tenantName: tenant.name }) }}
          checked={widget?.tenantAvailability}
          disabled={isAvailabilityDisabled}
          onWppChange={toggleCurrentTenantAvailability}
          required
          data-testid="widget-availability-toggle"
        />
        <InfoMessage
          size="m"
          show
          text={t('widgets|availability.info_message')}
          data-testid="availability-publish-info-message"
        />
      </Flex>
    </WppCard>
  )
}
