import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useGetDownloadUrlsApi } from 'api/attachments/queries/useGetDownloadUrlsApi'
import { useUpdateWidgetApiWithEffects } from 'api/widgets/mutations/useUpdateWidgetApi'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { useWidgetData } from 'pages/widget/hooks/useWidgetData'
import {
  getWidgetFormValidationSchema,
  getWidgetInitialValues,
  MAX_WIDGET_SIZES,
} from 'pages/widget/update/formUtils/formUtils'
import { useUploadWidgetCoverImage } from 'pages/widget/update/hooks/useUploadWidgetCoverImage'
import { useUploadWidgetCoverImageThumbnail } from 'pages/widget/update/hooks/useUploadWidgetCoverImageThumbnail'
import { ImageMetaDTO } from 'types/common/attachments'
import { API } from 'types/common/enums'
import { NativeWidgetFormDTO } from 'types/widgets/widget'
import { Flex } from 'ui-base/flex/Flex'
import { FormFileUpload } from 'ui-base/form/formFileUpload/FormFileUpload'
import { FormGridSizeSelect } from 'ui-base/form/formGridSizeSelect/FormGridSizeSelect'
import { FormTextArea } from 'ui-base/form/formTextArea/FormTextArea'
import { FormTextInput } from 'ui-base/form/formTextInput/FormTextInput'
import { getFormErrors, unpackApiError } from 'utils/form'

import * as S from 'pages/widget/update/WidgetDetailsPage.styled'

export const NativeWidgetUpdateForm = () => {
  const [memoizedCoverImageTempUrl, setMemoizedCoverImageTempUrl] = useState<string>()
  const { widget } = useWidgetData()
  const { mutateAsync: handleUpdateWidget } = useUpdateWidgetApiWithEffects()
  const { t } = useTranslation(['widgets', 'common', 'errors'])
  const { showToast } = useToast()

  const form = useForm<NativeWidgetFormDTO>({
    defaultValues: getWidgetInitialValues({ widget: widget! }),
    validationSchema: getWidgetFormValidationSchema(t),
  })

  const {
    handleSubmit,
    setErrors,
    watch,
    formState: { isSubmitting },
  } = form

  const sizesLength = watch('widgetSizes').length
  const coverImageArray = watch('logoMeta')
  const coverImage = coverImageArray[0]
  const coverImageTempUrl = coverImage?.downloadable?.signedUrl
  const shouldDownloadUrlEnabled =
    !memoizedCoverImageTempUrl &&
    !coverImageTempUrl &&
    !!widget?.logoMeta?.original &&
    widget?.logoMeta?.original?.key === coverImage?.key

  const { data: uploadedCoverImage } = useGetDownloadUrlsApi({
    params: {
      api: API.DEVHUB,
      keys: [coverImage?.key],
    },
    enabled: shouldDownloadUrlEnabled,
  })

  const coverImageUrl = memoizedCoverImageTempUrl || coverImageTempUrl || uploadedCoverImage?.[0]?.signedUrl

  const getThumbnailImage = useUploadWidgetCoverImageThumbnail(coverImage)
  const handleCoverImageUpload = useUploadWidgetCoverImage()

  useEffect(() => {
    if (!coverImage) {
      setMemoizedCoverImageTempUrl('')
    }
  }, [coverImage])

  const onSubmit = handleSubmit(async values => {
    try {
      let logoMeta
      if (coverImage) {
        const thumbnail = await getThumbnailImage()

        logoMeta = {
          original: { key: coverImage.key, name: coverImage.name, size: coverImage.size },
          thumbnail: {
            key: thumbnail?.key,
            name: thumbnail?.name,
            size: thumbnail?.size,
          },
        }
      }
      await handleUpdateWidget({ widgetData: { ...values, logoMeta: logoMeta as ImageMetaDTO }, widgetId: widget!.id })
      coverImageTempUrl && setMemoizedCoverImageTempUrl(coverImageTempUrl)

      showToast({ message: t('widgets|update.success.widget_updated'), type: 'success' })
    } catch (apiError) {
      const { status } = unpackApiError(apiError)
      const errors = getFormErrors(apiError)

      if (errors) {
        const { publicIfApplicationType, ...rest } = errors

        setErrors({
          ...rest,
          ...(!!publicIfApplicationType && {
            widgetType: publicIfApplicationType,
          }),
        })
      } else if (status === 403) {
        showToast({ message: t('errors|access_denied.action'), type: 'error' })
      } else {
        showToast({ message: t('errors|general'), type: 'error' })
      }
    }
  })

  return (
    <FormProvider {...form}>
      <S.UpdateForm onSubmit={onSubmit}>
        <WppCard size="xl">
          <div slot="header">
            <WppTypography type="xl-heading">{t('widgets|update.title')}</WppTypography>
          </div>
          <Flex direction="column" gap={24}>
            <FormTextInput
              labelConfig={{ text: t('widgets|update.fields.name.label') }}
              placeholder={t('widgets|update.fields.name.placeholder')}
              name="name"
              required
              data-testid="widget-name"
            />
            <FormTextArea
              labelConfig={{ text: t('widgets|update.fields.description.label') }}
              placeholder={t('widgets|update.fields.description.placeholder')}
              name="description"
              required
              data-testid="widget-description"
            />
            <FormTextInput
              labelConfig={{
                text: t('widgets|update.fields.bundle_url.label'),
                description: t('widgets|update.fields.bundle_url.hint'),
                icon: 'wpp-icon-info',
              }}
              placeholder={t('widgets|update.fields.bundle_url.placeholder')}
              name="bundleUrl"
              required
              data-testid="widget-bundle-url"
            />
            <FormGridSizeSelect
              labelConfig={{
                text: t('widgets|update.fields.widget_sizes.label'),
                description: t('widgets|update.fields.widget_sizes.hint'),
                icon: 'wpp-icon-info',
              }}
              name="widgetSizes"
              required
              disabled={sizesLength >= MAX_WIDGET_SIZES}
              buttonTitle={t('widgets|update.fields.widget_sizes.button')}
              dataTestId="widget-sizes"
            />
            <FormFileUpload
              handleFileChange={handleCoverImageUpload}
              labelConfig={{
                text: t('widgets|update.fields.cover_image.label'),
                description: t('widgets|update.fields.cover_image.hint'),
                icon: 'wpp-icon-info',
              }}
              multiple={false}
              acceptConfig={{ 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] }}
              name="logoMeta"
              disabled={isSubmitting}
              size={5}
              maxFiles={1}
              showOnlyNewErrors
              data-testid="widget-upload-cover-image"
            >
              {coverImageUrl && (
                <S.CoverImageWrapper size="s" variant="secondary">
                  <S.CoverImage src={coverImageUrl} alt="cover-image" data-testid="widget-cover-image" />
                </S.CoverImageWrapper>
              )}
            </FormFileUpload>

            <Flex gap={12}>
              <WppButton loading={isSubmitting} type="submit" data-testid="widget-update-submit">
                {t('common|save')}
              </WppButton>
            </Flex>
          </Flex>
        </WppCard>
      </S.UpdateForm>
    </FormProvider>
  )
}
