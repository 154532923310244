import { useMemo } from 'react'

import { useProductCategoriesConfigApi } from 'api/products/queries/useProductCategoriesConfigApi'
import { ProductCategoryObject, ProductSubcategoryObject } from 'types/products/product'
import { Option } from 'ui-base/form/singleSelect/SingleSelect'

interface SubcategoryOption extends Option {
  logo: string
}

interface SubcategoryOptions {
  [key: string]: SubcategoryOption[]
}

export const useProductCategories = () => {
  const {
    data: categoriesConfig,
    isLoading: isProductCategoriesLoading,
    isError: isProductCategoriesError,
    error: productCategoriesError,
  } = useProductCategoriesConfigApi({
    staleTime: Infinity,
  })

  const subcategoriesMap = useMemo(
    () =>
      categoriesConfig &&
      (Object.values(categoriesConfig).reduce((acc, curr) => {
        if (curr.subcategories) {
          const subcategoriesMap = curr.subcategories.reduce((acc, curr) => {
            return { ...acc, [curr.value as string]: curr }
          }, {})
          return { ...acc, ...subcategoriesMap }
        }
        return acc
      }, {}) as Record<string, ProductSubcategoryObject>),
    [categoriesConfig],
  )

  const categoryOptions = useMemo(
    () =>
      categoriesConfig &&
      Object.values(categoriesConfig).map(category => ({
        label: category.name,
        value: category.value,
        logo: category.logoUrl,
      })),
    [categoriesConfig],
  )

  const subcategoryOptions = useMemo(
    () =>
      categoriesConfig &&
      (Object.values(categoriesConfig).reduce((acc, curr) => {
        if (curr.subcategories) {
          return {
            ...acc,
            [curr.value]: curr.subcategories.map(subcategory => ({
              label: subcategory.name,
              value: subcategory.value,
              logo: subcategory.logoUrl,
            })),
          }
        }
        return acc
      }, {}) as SubcategoryOptions),
    [categoriesConfig],
  )

  const defaultCategory = useMemo(
    () =>
      categoriesConfig &&
      (Object.values(categoriesConfig).find(category => category.defaultCategory) as ProductCategoryObject),
    [categoriesConfig],
  )

  return {
    categoriesConfig: categoriesConfig!,
    categoryOptions,
    subcategoryOptions,
    subcategoriesMap,
    defaultCategory,
    isProductCategoriesLoading,
    isProductCategoriesError,
    productCategoriesError,
  }
}
