import { AttachmentDownloadMeta, CoreApiAttachmentDownloadMeta } from 'types/common/attachments'
import { API } from 'types/common/enums'
import { getApiInstance } from 'utils/api'

interface Params {
  api: API
  keys: string[]
}

export const getDownloadUrlsApi = ({ api, keys }: Params) =>
  getApiInstance(api).post<AttachmentDownloadMeta[]>('/object-storage/perm/generate-download-urls', { keys })

export const getDownloadUrlsCoreApi = ({ api, keys }: Params) =>
  getApiInstance(api).post<CoreApiAttachmentDownloadMeta[]>('/images/generate-perm-download-urls', { keys })
