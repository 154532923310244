import { useRef, useCallback, useEffect } from 'react'
import { RefCallBack } from 'react-hook-form'

import { MayBeNull } from 'types/common/utils'

/*
  Hook that provides stable ref functionality
  for component-library webcomponents and getting
  interactive elements nodes inside them.

   Ex.

  import { useField } from 'hooks/form/useField'

  const {
    field: { ref: fieldRef },    
  } = controller

  const checkboxRef = useWebComponentRef<HTMLWppCheckboxElement, HTMLInputElement>({
    fieldRef,
    getInteractiveElement: el => el.querySelector('input'),
  })

  <WppCheckbox ref={checkboxRef} />
*/
export const useWebComponentRef = <
  WebComponentInstance extends HTMLElement,
  InteractiveElement extends {
    focus: () => void
    setCustomValidity?: (error: string) => void
    reportValidity?: () => boolean
  } = any,
>({
  fieldRef,
  getInteractiveElement,
}: {
  /*
    Commonly this is ref provided from useField hook.
    Also it can be any fuction ref, or composition of mergeRefs
  */
  fieldRef: RefCallBack
  /*
    Method to get interactive ref inside webcomponent

    Ex.:

    const forShadowedDOM = el => el.shadowRoot.querySelector('...')
    const forRegularDOM = el => el.querySelector('...')
  */
  getInteractiveElement: (webComponent: WebComponentInstance) => MayBeNull<InteractiveElement>
}) => {
  // Inline functions and refs passing support
  const persistBetweenRenders = {
    fieldRef,
    getInteractiveElement,
  }

  const persistBetweenRendersRef = useRef(persistBetweenRenders)
  persistBetweenRendersRef.current = persistBetweenRenders

  // Latest call observer ref or null
  const observerRef = useRef<MayBeNull<MutationObserver>>(null)

  // Prevent memory leaks
  const cleanupObserver = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect()
      observerRef.current = null
    }
  }, [])

  // Dispose and clean observer if any on unmount
  useEffect(() => cleanupObserver, [cleanupObserver])

  return useCallback(
    (webComponent: MayBeNull<WebComponentInstance>) => {
      const getInteractiveElement = (webComponent: WebComponentInstance) =>
        persistBetweenRendersRef.current.getInteractiveElement(webComponent)

      const setRef = (interactiveElement: MayBeNull<InteractiveElement>) => {
        persistBetweenRendersRef.current.fieldRef(interactiveElement)
      }

      // Cleanup observer from previous call if any
      cleanupObserver()

      if (!webComponent) {
        setRef(null)
      } else {
        // Check interactive element presence
        const node = getInteractiveElement(webComponent)

        if (node) {
          setRef(node)
        } else {
          /*
            It takes time for webcomponent to register, initialize and render its content.
            So on first render there is no content inside webcomponent.

            We can track is content was rendered inside webcomponent
            via childlist observation using Mutation observer
            to preserve stable refs for interactive element inside.
          */
          observerRef.current = new MutationObserver(() => {
            // check if node is found
            const node = getInteractiveElement(webComponent)

            if (node) {
              /*
                If found - cleanup newly created observer
                cause we are not interested to track subsequent mutations
              */
              cleanupObserver()
              setRef(node)
            }
          })

          // Start tracking
          observerRef.current.observe(webComponent, {
            childList: true,
            subtree: true,
          })
        }
      }
    },
    [cleanupObserver],
  )
}
