export interface ProductTenant {
  tenantId: string
  tenantName: string
  tenantDescription?: string
  access: TenantStatus
}

export enum TenantStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
