import { WppIconHistory } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { WidgetBackButton } from 'pages/widget/components/WidgetHeader/WidgetBackButton/WidgetBackButton'
import { useWidgetData } from 'pages/widget/hooks/useWidgetData'
import { Flex } from 'ui-base/flex/Flex'
import { WidgetTypeIcon } from 'ui-base/svgIcons/widgetTypeIcon/WidgetTypeIcon'
import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { Truncate } from 'ui-base/truncate/Truncate'
import { ProductOnboardingTag } from 'ui-shared/productOnboardingTag/ProductOnboardingTag'
import dayjs from 'utils/date'

import * as S from 'pages/widget/components/WidgetHeader/WidgetHeader.styled'

interface Props {
  breadcrumbLabel?: string
  renderRight?: ReactNode
}

export const WidgetHeader = ({ breadcrumbLabel, renderRight, ...rest }: Props) => {
  const { t } = useTranslation(['common', 'widgets'])
  const { widget } = useWidgetData()

  return (
    <S.HeaderWrapper justify="between" align="center" {...rest} data-testid="header-container">
      <S.Left direction="column" gap={8}>
        <WidgetBackButton />
        <Tooltip
          show
          showOnOverflow
          text={widget!.name}
          getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
          config={{ placement: 'top' }}
        >
          <Truncate type="3xl-heading" tag="h1" data-testid="widget-title-name">
            {widget!.name}
          </Truncate>
        </Tooltip>
        <Flex gap={16} align="center">
          <WidgetTypeIcon withLabel size={20} widgetType={widget?.widgetType!} />
          <S.Divider />
          <ProductOnboardingTag status={widget!.publishOnboardingStatus} />
          <S.Divider />
          <Flex gap={8}>
            <WppIconHistory color="var(--wpp-grey-color-800)" />
            <S.TypographyGrey type="xs-body" data-testid="widget-update-time">
              {t('common|updated', { when: dayjs(widget?.updatedAt!).fromNow() })}
            </S.TypographyGrey>
          </Flex>
        </Flex>
      </S.Left>
      {renderRight}
    </S.HeaderWrapper>
  )
}
