import { useCallback, useRef } from 'react'

import { useGetUploadUrlsApi } from 'api/attachments/mutations/useGetUploadUrlsApi'
import { useUploadToSignedUrlApi } from 'api/attachments/mutations/useUploadToSignedUrlApi'
import { AttachmentsAcceptGroup, AttachmentUploadResult } from 'types/common/attachments'
import { API } from 'types/common/enums'
import { getDataUrl } from 'utils/attachments'

interface Params {
  api: API
  acceptGroup: AttachmentsAcceptGroup
  generateDownloadable?: boolean
}

interface Hook {
  handleUploadFiles: HandleUploadFilesFn
  reset: (id: string) => void
  isUploading: boolean
}

export interface FileUploadParams {
  id?: string
  file: File
}

export type HandleUploadFilesFn = (params: FileUploadParams) => Promise<AttachmentUploadResult>

export const useFileUpload = ({ api, acceptGroup, generateDownloadable = true }: Params): Hook => {
  const { mutateAsync: handleGetUploadUrls, isLoading: isGettingUploadUrl } = useGetUploadUrlsApi()
  const { mutateAsync: handleUploadToSignedUrl, isLoading: isUploadingToSignedUrl } = useUploadToSignedUrlApi()

  const abortControllerRef = useRef<Record<string, AbortController>>({})

  const handleUploadFiles: HandleUploadFilesFn = useCallback(
    async ({ id, file }: FileUploadParams) => {
      abortControllerRef.current[id!] = new AbortController()

      const uploadMetaList = await handleGetUploadUrls({
        api,
        names: [file.name],
        signal: abortControllerRef.current[id!].signal,
      })

      const meta = uploadMetaList.data?.[0]
      if (file.name !== meta.name) {
        throw new Error(`Uploaded file name "${file.name}" doesn't match the name in returned meta: "${meta.name}"`)
      }

      await handleUploadToSignedUrl({
        api,
        url: meta.signedUrl,
        file,
        acceptGroup,
        signal: abortControllerRef.current[id!].signal,
      })

      return {
        attachment: {
          id,
          key: meta.key,
          name: meta.name,
          size: file.size,
        },
        ...(generateDownloadable
          ? {
              downloadable: {
                key: meta.key,
                signedUrl: await getDataUrl(file),
              },
            }
          : null),
      }
    },
    [api, handleGetUploadUrls, handleUploadToSignedUrl, acceptGroup, generateDownloadable],
  )

  const reset = useCallback((id: string) => {
    abortControllerRef.current[id]?.abort()
  }, [])

  return { isUploading: isGettingUploadUrl || isUploadingToSignedUrl, handleUploadFiles, reset }
}
