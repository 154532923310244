import clsx from 'clsx'
import { ElementType, forwardRef, ComponentPropsWithoutRef, ComponentRef, ComponentProps, Ref } from 'react'

import * as S from 'ui-base/flex/Flex.styled'

interface OwnProps {
  inline?: boolean
  gap?: number
  grow?: number
  justify?: 'start' | 'flex-start' | 'center' | 'end' | 'flex-end' | 'between' | 'around'
  align?: 'start' | 'center' | 'end' | 'stretch'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  direction?: 'row' | 'column'
}

interface FlexImplementation {
  <T extends ElementType>(
    props: ComponentPropsWithoutRef<T> & { as: T; ref?: Ref<ComponentRef<T>> } & OwnProps,
  ): JSX.Element
  (props: ComponentProps<'div'> & { as?: never } & OwnProps): JSX.Element
}

export const Flex = forwardRef(function Flex(props: Omit<Parameters<FlexImplementation>[0], 'ref'>, ref) {
  const {
    as: As = 'div',
    inline = false,
    direction = 'row',
    align,
    wrap,
    justify,
    gap,
    grow,
    className,
    style,
    ...rest
  } = props

  return (
    <S.Container
      as={As}
      ref={ref}
      {...rest}
      style={{ ...style, ...(!!gap && { gap: `${gap}px` }), ...(!!grow && { flexGrow: grow }) }}
      className={clsx(
        'root',
        {
          inline,
          column: direction === 'column',
          [`align-${align}`]: !!align,
          [`${wrap}`]: !!wrap,
          [`justify-${justify}`]: !!justify,
        },
        className,
      )}
    />
  )
}) as FlexImplementation
