export const ellipsize = (value: string, size: number) =>
  value.length > size ? `${value.slice(0, size).trimEnd()}...` : value

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

export const camelCaseToSnakeCase = (str: string): string => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const stringToSnakeCase = (str: string): string =>
  str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_')

export const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$|^$/
