import { Path } from 'react-hook-form'
import { UseFormSetError } from 'react-hook-form/dist/types/form'

import { ApiResponseError } from 'types/api/api'

export const unpackApiError = (apiError: any) => {
  const { violations, status, detail, code } = (apiError as ApiResponseError)?.response?.data || {}

  return { violations, status, detail, code }
}

export const getFormErrors = (apiError: any) => {
  const { violations } = unpackApiError(apiError)

  if (violations) {
    return violations.reduce<Record<string, string>>((acc, { field, message }) => ({ ...acc, [field]: message }), {})
  }
}

// Utility to set batched form errors
export const setErrors = <T extends {}>(errors: Record<string, string>, setError: UseFormSetError<T>) => {
  Object.entries(errors).forEach(([fieldName, errorText], index) => {
    setError(fieldName as Path<T>, { message: errorText as string }, { shouldFocus: index === 0 })
  })
}

export const getFieldsWithError = (detail: string) => detail?.match(/\[(.*?)\]/)?.[1].split(/\s*,\s*/) || []
