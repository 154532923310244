import { ReactElement, lazy } from 'react'

import { WidgetDetailsContainer } from 'pages/widget/update/WidgetDetailsContainer'
import { RoutesManager } from 'utils/routesManager'

interface AppRoute {
  Component: ReactElement
  path: string
}

const MyProductsPage = lazy(() =>
  import('pages/myProducts/MyProductsPage').then(({ MyProductsPage }) => ({
    default: MyProductsPage,
  })),
)

const ProductContainerPage = lazy(() =>
  import('pages/product/ProductContainer').then(({ ProductContainer }) => ({
    default: ProductContainer,
  })),
)

const V2ProductContainerPage = lazy(() =>
  import('pages/product/V2ProductContainer').then(({ V2ProductContainer }) => ({
    default: V2ProductContainer,
  })),
)

const OverviewPage = lazy(() =>
  import('pages/overview/OverviewPage').then(({ OverviewPage }) => ({
    default: OverviewPage,
  })),
)

const MyWidgetsPage = lazy(() =>
  import('pages/myWidgets/MyWidgetsPage').then(({ MyWidgetsPage }) => ({
    default: MyWidgetsPage,
  })),
)

const AdminPage = lazy(() =>
  import('pages/admin/AdminPage').then(({ AdminPage }) => ({
    default: AdminPage,
  })),
)

export const routes: AppRoute[] = [
  {
    Component: <MyProductsPage />,
    path: RoutesManager.myProducts.root.pattern,
  },
  {
    Component: <ProductContainerPage />,
    path: RoutesManager.products.root.pattern,
  },
  {
    Component: <V2ProductContainerPage />,
    path: RoutesManager.products.rootV2.pattern,
  },
  {
    Component: <OverviewPage />,
    path: RoutesManager.overview.root.pattern,
  },
  {
    Component: <MyWidgetsPage />,
    path: RoutesManager.myWidgets.root.pattern,
  },
  {
    Component: <WidgetDetailsContainer />,
    path: RoutesManager.widgets.update.pattern,
  },
  {
    Component: <AdminPage />,
    path: RoutesManager.admin.root.pattern,
  },
]
