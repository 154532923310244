import { useMemo, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ApiErrorTypes } from 'constants/apiErrors'
import { ErrorStateContent } from 'ui-base/errorState/components/ErrorStateContent'
import { AccessIconSvg } from 'ui-base/svgIcons/errorState/assets/AccessIconSvg'
import { CrossIconSvg } from 'ui-base/svgIcons/errorState/assets/CrossIconSvg'
import { OopsIconSvg } from 'ui-base/svgIcons/errorState/assets/OopsIconSvg'
import { ServerIconSvg } from 'ui-base/svgIcons/errorState/assets/ServerIconSvg'
import { RoutesManager } from 'utils/routesManager'

interface Props extends HTMLAttributes<HTMLDivElement> {
  errorType: ApiErrorTypes
  margin?: string
  fullHeight?: boolean
}

export const ErrorState = ({ errorType, ...rest }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['errors'])

  const pageConfig = useMemo(
    () =>
      ({
        [ApiErrorTypes.NOT_FOUND]: {
          image: <OopsIconSvg />,
          title: t('errors|not_found'),
          buttonText: t('errors|go_to_my_products'),
          buttonAction: () => navigate(RoutesManager.myProducts.root.getURL()),
        },
        [ApiErrorTypes.ACCESS_DENIED]: {
          image: <AccessIconSvg />,
          title: t('errors|access_denied.page'),
          buttonText: t('errors|go_to_my_products'),
          buttonAction: () => navigate(RoutesManager.myProducts.root.getURL()),
        },
        [ApiErrorTypes.SERVER_ERROR]: {
          image: <ServerIconSvg />,
          title: t('errors|server_error'),
          buttonText: t('errors|reload_page'),
          buttonAction: () => navigate(0),
        },
        [ApiErrorTypes.OTHER_ERROR]: {
          image: <CrossIconSvg />,
          title: t('errors|global_error'),
          buttonText: t('errors|reload_page'),
          buttonAction: () => navigate(0),
        },
      }[errorType]),
    [errorType, navigate, t],
  )

  return (
    <ErrorStateContent
      image={pageConfig.image}
      title={pageConfig.title}
      buttonText={pageConfig.buttonText}
      handleClick={pageConfig.buttonAction}
      {...rest}
    />
  )
}
