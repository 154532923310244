import { styled } from '@mui/material/styles'

import { SpinnerVariant } from 'ui-base/spinner/Spinner'

export const Spinner = styled('div', { shouldForwardProp: prop => prop !== 'variant' })<{ variant: SpinnerVariant }>`
  justify-content: center;
  align-items: center;
  display: ${({ variant }) => (variant === 'block' ? 'flex' : 'inline-flex')};
  padding: ${({ variant }) => (variant === 'block' ? '30px' : 0)};
  height: 100%;
  width: 100%;
`
