import { PropsWithChildren } from 'react'

import { ViewOnlyBanner } from 'pages/product/update/components/viewOnlyBanner/ViewOnlyBanner'

import * as S from 'layout/content/Content.styled'

export const Content = ({ children }: PropsWithChildren) => (
  <>
    <ViewOnlyBanner />
    <S.Wrapper>
      <S.GridContainer container direction="column" alignItems="center" fullHeight rowSpacing={2}>
        <S.GridItem item all={24}>
          {children}
        </S.GridItem>
      </S.GridContainer>
    </S.Wrapper>
  </>
)
