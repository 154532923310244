import { WppInput } from '@platform-ui-kit/components-library-react'
import { forwardRef, ComponentProps, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'
import { useWebComponentRef } from 'hooks/form/useWebComponentRef'

interface Props
  extends Omit<
    ComponentProps<typeof WppInput>,
    'name' | 'value' | 'onChange' | 'onBlur' | 'onWppBlur' | 'onWppChange'
  > {
  name: string
  transformFn?: (val: string) => string
}

export const FormTextInput = forwardRef<HTMLWppInputElement, Props>(
  ({ id, name, labelConfig, message, messageType, transformFn, ...rest }, ref) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
      fieldState: { error, isTouched },
    } = useField({
      name,
    })
    const { t } = useTranslation()

    const identifier = id || name
    const errorText = error?.message
    const shouldShowError = !!errorText && isTouched

    const inputRef = useWebComponentRef<HTMLWppInputElement, HTMLInputElement>({
      fieldRef,
      getInteractiveElement: el => el.shadowRoot?.querySelector('input')!,
    })

    const handleChange = useCallback(
      (event: CustomEvent) => {
        const value = event.detail?.value
        const transformedVal = transformFn ? transformFn(value) : value
        onChange(transformedVal)
      },
      [transformFn, onChange],
    )

    return (
      <WppInput
        {...rest}
        data-errortype={error?.type}
        ref={mergeRefs([ref, inputRef])}
        id={identifier}
        name={name}
        {...(labelConfig && {
          labelConfig: {
            ...labelConfig,
            locales: { optional: t('optional') },
          },
        })}
        value={value}
        onWppChange={handleChange}
        onWppBlur={onBlur}
        message={shouldShowError ? errorText : message}
        messageType={shouldShowError ? 'error' : messageType}
      />
    )
  },
)
