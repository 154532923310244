import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const CrossIconSvg = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="50.5" cy="50" r="50" className="fillPrimary200" />
    <rect x="30" y="29" width="42" height="42" rx="8" className="fillPrimary500" />
    <path
      d="M59.0767 41.9231L42.9229 58.0769"
      className="strokePrimary200"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.9229 41.9231L59.0767 58.0769"
      className="strokePrimary200"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.CustomSvg>
)
