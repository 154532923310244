import { styled } from '@mui/material'

export const Container = styled('div')`
  &.root {
    display: flex;
  }

  &.inline {
    display: inline-flex;
  }

  &.column {
    flex-direction: column;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.justify-start {
    justify-content: start;
  }

  &.justify-flex-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: end;
  }

  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }
`
