import { useOs } from '@wpp-open/react'
import { Route, Navigate, createRoutesFromElements, createBrowserRouter, RouterProvider } from 'react-router-dom'

import { routes } from 'app/routes'
import { ApiErrorTypes } from 'constants/apiErrors'
import { Layout } from 'layout/Layout'
import { InternalApiErrorProvider } from 'providers/internalApiError/InternalApiErrorProvider'
import { ErrorPage } from 'ui-base/errorPage/ErrorPage'
import { RoutesManager } from 'utils/routesManager'

export const AppRoutes = () => {
  const {
    osContext: { baseUrl },
  } = useOs()

  const accessibleRoutesList = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          // InternalApiErrorProvider should be placed in root Route to be able to use useLocation hook inside
          <InternalApiErrorProvider>
            <Layout />
          </InternalApiErrorProvider>
        }
      >
        <Route key="myProducts" index element={<Navigate to={RoutesManager.myProducts.root.getURL()} replace />} />
        {routes.map(({ Component, path }, index) => (
          <Route key={path || index} path={path} element={Component} />
        ))}
        <Route path="*" key="not-found" element={<ErrorPage errorType={ApiErrorTypes.NOT_FOUND} />} />
      </Route>,
    ),
    { basename: `/${baseUrl}` },
  )

  return <RouterProvider router={accessibleRoutesList} />
}
