import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const ErrorStateContent = styled(Flex, {
  shouldForwardProp: props => props !== 'margin' && props !== 'fullHeight',
})<{
  margin?: string
  fullHeight?: boolean
}>`
  margin: ${({ margin }) => (!!margin ? margin : 'auto')};
  ${props =>
    !!props.fullHeight &&
    css`
      height: 100%;
      width: 100%;
    `}
`

export const PageImage = styled('div')`
  width: 100px;
  height: 100px;
  margin-bottom: 32px;
`

export const PageTitle = styled(WppTypography)`
  margin-bottom: 16px;

  &::part(typography) {
    text-align: center;
  }
`
