import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const ServerIconSvg = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="50.5" cy="50" r="50" className="fillPrimary200" />
    <rect x="24" y="27" width="46.5" height="12.75" rx="6.375" className="fillPrimary500" />
    <rect x="24" y="42" width="46.5" height="12.75" rx="6.375" className="fillPrimary500" />
    <rect x="24" y="57" width="46.5" height="12.75" rx="6.375" className="fillPrimary500" />
    <path
      d="M74.0288 49.8102C71.6622 46.174 66.3378 46.174 63.9712 49.8102L51.212 69.4146C48.6144 73.406 51.4786 78.6875 56.2408 78.6875H81.7592C86.5214 78.6875 89.3856 73.406 86.788 69.4146L74.0288 49.8102Z"
      className="fillPrimary500 fillPrimary200"
      strokeWidth="4"
    />
    <path
      d="M70.4403 58L70.206 68.2699H67.919L67.6918 58H70.4403ZM69.0625 72.7017C68.6316 72.7017 68.2623 72.5502 67.9545 72.2472C67.6515 71.9441 67.5 71.5748 67.5 71.1392C67.5 70.7131 67.6515 70.3485 67.9545 70.0455C68.2623 69.7424 68.6316 69.5909 69.0625 69.5909C69.4839 69.5909 69.8485 69.7424 70.1563 70.0455C70.4688 70.3485 70.625 70.7131 70.625 71.1392C70.625 71.428 70.5516 71.6908 70.4048 71.9276C70.2628 72.1643 70.0734 72.3537 69.8366 72.4957C69.6046 72.6331 69.3466 72.7017 69.0625 72.7017Z"
      className="fillPrimary200"
    />
    <circle cx="30.5" cy="33.5" r="2.5" className="fillPrimary200" />
    <circle cx="37.5" cy="33.5" r="2.5" className="fillPrimary200" />
    <circle cx="30.5" cy="48.5" r="2.5" className="fillPrimary200" />
    <circle cx="37.5" cy="48.5" r="2.5" className="fillPrimary200" />
    <circle cx="30.5" cy="63.5" r="2.5" className="fillPrimary200" />
    <circle cx="37.5" cy="63.5" r="2.5" className="fillPrimary200" />
  </S.CustomSvg>
)
