import { Global as GlobalStyles, css } from '@emotion/react'
import { useContext, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useProductCategories } from 'hooks/useProductCategories'
import { Content } from 'layout/content/Content'
import { Header } from 'layout/header/Header'
import { InternalApiErrorContext } from 'providers/internalApiError/InternalApiErrorContext'
import { CustomWppSpinner } from 'ui-base/spinner/Spinner'
import { handleApiErrors } from 'utils/api'

export const Layout = () => {
  const { isAuthenticated } = useAuth()
  const { error } = useContext(InternalApiErrorContext)
  const { isProductCategoriesLoading, isProductCategoriesError, productCategoriesError } = useProductCategories()

  const isReady = isAuthenticated && !isProductCategoriesLoading
  const hideHeader = !!error && error?.response?.status >= 500
  const pageChildren =
    !!error || isProductCategoriesError ? handleApiErrors({ error: error || productCategoriesError }) : <Outlet />

  return (
    <>
      {!hideHeader && <Header />}
      <Content>
        <Suspense fallback={<CustomWppSpinner delay={200} size="l" />}>
          {isReady ? pageChildren : <CustomWppSpinner size="l" />}
        </Suspense>
      </Content>
      <GlobalStyles // TODO update it from the OS theme
        styles={css`
          body {
            background: var(--wpp-grey-color-100);
            --wpp-devhub-header-height: 49px;
          }

          #root,
          body,
          html {
            height: auto;
          }
        `}
      />
    </>
  )
}
