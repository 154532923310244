import { HTMLAttributes } from 'react'

import { ApiErrorTypes } from 'constants/apiErrors'
import { ErrorState } from 'ui-base/errorState/ErrorState'

import * as S from 'ui-base/errorPage/ErrorPage.styled'

interface Props extends HTMLAttributes<HTMLDivElement> {
  errorType: ApiErrorTypes
}

export const ErrorPage = ({ errorType, ...rest }: Props) => (
  <S.PageWrapper align="center" justify="center" {...rest}>
    <ErrorState errorType={errorType} />
  </S.PageWrapper>
)
