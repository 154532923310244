import { createUseQuery } from 'api/common/createUseQuery'
import { fetchTipCards } from 'api/tipCards/fetchers/fetchTipCards'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { parsePropTipData } from 'providers/tipCard/utils'

export const useTipCardsApi = createUseQuery({
  queryKey: ApiQueryKeys.TIP_CARDS,
  fetcher: fetchTipCards,
  selector: res => parsePropTipData(res?.data || []),
})
