import {
  WppCard,
  WppIconFlagOn,
  WppIconInfo,
  WppIconWithHand,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { ComponentProps, PropsWithChildren, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MessageTypes } from 'types/common/enums'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'ui-shared/infoMessage/InfoMessage.styled'

interface Props extends Omit<ComponentProps<typeof WppCard>, 'variant'> {
  messageType?: MessageTypes
  title?: string
  text?: string
  linkText?: string
  linkUrl?: string
  isExternalLink?: boolean
  show?: boolean
  customDescriptionRenderer?: ReactNode
  isNoTitle?: boolean
}

interface CardOptions {
  title: string
  icon: ReactNode
  backgroundColor: string
  backgroundOpacity?: number
}

export const InfoMessage = ({
  messageType = MessageTypes.IMPORTANT,
  title,
  size = 'xl',
  children,
  text,
  linkText,
  linkUrl,
  isExternalLink,
  show = true,
  customDescriptionRenderer,
  isNoTitle,
  ...rest
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation(['common'])
  const isLargeCard = !!size && size !== 's' && size !== 'm'

  const config = useMemo(
    () =>
      ((
        {
          [MessageTypes.IMPORTANT]: {
            title: t('common|important'),
            icon: (
              <WppIconFlagOn
                size="m"
                color="var(--wpp-dataviz-color-cat-dark-9)"
                {...(isLargeCard && { width: 24, height: 24 })}
              />
            ),
            backgroundColor: 'var(--wpp-dataviz-color-cat-neutral-9)',
            backgroundOpacity: 20,
          },
          [MessageTypes.INFORMATION]: {
            title: t('common|information'),
            icon: <WppIconInfo size="m" />,
            backgroundColor: 'var(--wpp-dataviz-color-cat-neutral-9)',
            backgroundOpacity: 20,
          },
          [MessageTypes.WARNING]: {
            title: t('common|warning'),
            icon: (
              <WppIconWithHand
                size="m"
                color="var(--wpp-dataviz-color-seq-warning-600)"
                {...(isLargeCard && { width: 24, height: 24 })}
              />
            ),
            backgroundColor: 'var(--wpp-dataviz-color-seq-warning-100)',
          },
        } as { [key in MessageTypes]: CardOptions }
      )[messageType]),
    [isLargeCard, messageType, t],
  )

  if (!show) {
    return null
  }

  return (
    <S.Card
      backgroundColor={config.backgroundColor}
      backgroundOpacity={config.backgroundOpacity!}
      size={size}
      variant="secondary"
      {...rest}
    >
      <Flex slot="header" align="center" gap={6}>
        {config.icon}
        <>
          {!customDescriptionRenderer ? (
            <WppTypography type={isLargeCard ? 'xl-heading' : 'm-strong'}>
              {title || (!isNoTitle && config.title)}
            </WppTypography>
          ) : (
            customDescriptionRenderer
          )}
        </>
      </Flex>
      {!!text && <S.Description type="s-body">{text}</S.Description>}
      {!!linkText && !!linkUrl && (
        <S.Link to={linkUrl!} {...(isExternalLink && { target: '_blank', rel: 'noopener noreferrer' })}>
          <WppTypography type="s-body">{linkText}</WppTypography>
        </S.Link>
      )}
      {children}
    </S.Card>
  )
}
