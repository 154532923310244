import { useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { usePatchTipCardApi } from 'api/tipCards/mutations/usePatchTipCardApi'
import { useTipCardsApi } from 'api/tipCards/queries/useTipCardsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { TipCardContext } from 'providers/tipCard/TipCardContext'
import { TipId } from 'types/tipCard/enums'
import { TipCardItem } from 'types/tipCard/tipCard'

export const TipCardProvider = ({ children }: PropsWithChildren) => {
  const { data, isLoading } = useTipCardsApi()
  const { showToast } = useToast()
  const queryClient = useQueryClient()
  const { t } = useTranslation(['errors'])
  const { mutateAsync: updateTip } = usePatchTipCardApi()

  const updateCache = useCallback(
    (tipId: TipId) =>
      queryClient.setQueryData([ApiQueryKeys.TIP_CARDS, {}], (oldData: AxiosResponse<TipCardItem[]> | undefined) => {
        if (!oldData) return undefined
        // there might not be an Item with the given tipId, but we have to add it if it's not present
        const hasId = oldData?.data.some(item => item.id === tipId)
        let updatedData
        if (hasId) {
          updatedData = oldData?.data.map(item => (item.id === tipId ? { ...item, enabled: false } : item))
        } else {
          updatedData = [
            ...oldData?.data,
            {
              id: tipId,
              enabled: false,
            },
          ]
        }
        return {
          ...oldData,
          data: updatedData,
        }
      }),
    [queryClient],
  )

  const [{ loading: isUpdating }, handleClose] = useAsyncFn(
    async (tipId: TipId) => {
      try {
        updateCache(tipId)
        await updateTip({
          payload: {
            enabled: false,
            id: tipId,
          },
        })
      } catch {
        showToast({
          message: t('errors|general'),
          type: 'error',
        })
      }
    },
    [updateCache, showToast, t, updateTip],
  )

  return (
    <TipCardContext.Provider
      value={{
        state: data,
        handleClose,
        isLoading,
        isUpdating,
      }}
    >
      {children}
    </TipCardContext.Provider>
  )
}
