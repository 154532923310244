import { createTheme, ThemeOptions, PaletteColor, PaletteColorOptions } from '@mui/material/styles'

import { colors } from 'providers/styles/colors'

declare module '@mui/material/styles' {
  interface Palette {
    white: PaletteColor
    black: PaletteColor
    darkGrey: PaletteColor
    whiteSmoke: PaletteColor
    whiteSmoke2: PaletteColor
    whiteSmoke3: PaletteColor
    echoBlue: PaletteColor
    blueBayoux: PaletteColor
    blueBayoux2: PaletteColor
    blueBayoux56: PaletteColor
    nightRider: PaletteColor
    thunderbird: PaletteColor
    mediumBlue: PaletteColor
    periwinkle: PaletteColor
    zircon: PaletteColor
    solitude: PaletteColor
    silver: PaletteColor
    clearDay: PaletteColor
    acadia: PaletteColor
    narvik: PaletteColor
    navyBlue: PaletteColor
    dimGrey: PaletteColor
  }

  interface PaletteOptions {
    white?: PaletteColorOptions
    black?: PaletteColorOptions
    darkGrey?: PaletteColorOptions
    whiteSmoke?: PaletteColorOptions
    whiteSmoke2?: PaletteColorOptions
    whiteSmoke3?: PaletteColorOptions
    echoBlue?: PaletteColorOptions
    blueBayoux?: PaletteColorOptions
    blueBayoux2?: PaletteColorOptions
    blueBayoux56: PaletteColorOptions
    nightRider?: PaletteColorOptions
    thunderbird?: PaletteColorOptions
    mediumBlue?: PaletteColorOptions
    periwinkle?: PaletteColorOptions
    zircon?: PaletteColorOptions
    solitude?: PaletteColorOptions
    silver?: PaletteColorOptions
    clearDay?: PaletteColorOptions
    acadia?: PaletteColorOptions
    narvik?: PaletteColorOptions
    navyBlue?: PaletteColorOptions
    dimGrey?: PaletteColorOptions
  }
}

export let theme = createTheme({
  palette: {
    primary: {
      main: colors.white,
    },
    secondary: {
      main: colors.blueBayoux,
    },
    error: {
      main: colors.thunderbird,
    },
    text: {
      primary: colors.blueBayoux,
      secondary: colors.nightRider,
    },
    black: {
      main: colors.black,
      light: colors.black23,
    },
    white: {
      main: colors.white,
    },
    darkGrey: {
      main: colors.darkGrey,
    },
    whiteSmoke: {
      main: colors.whiteSmoke,
    },
    whiteSmoke2: {
      main: colors.whiteSmoke2,
    },
    whiteSmoke3: {
      main: colors.whiteSmoke3,
    },
    echoBlue: {
      main: colors.echoBlue,
    },
    blueBayoux: {
      main: colors.blueBayoux,
    },
    blueBayoux2: {
      main: colors.blueBayoux2,
    },
    blueBayoux56: {
      main: colors.blueBayoux56,
    },
    nightRider: {
      main: colors.nightRider,
    },
    thunderbird: {
      main: colors.thunderbird,
    },
    mediumBlue: {
      main: colors.mediumBlue,
    },
    periwinkle: {
      main: colors.periwinkle,
    },
    zircon: {
      main: colors.zircon,
    },
    solitude: {
      main: colors.solitude,
    },
    silver: {
      main: colors.silver,
    },
    clearDay: {
      main: colors.clearDay,
    },
    acadia: {
      main: colors.acadia,
    },
    narvik: {
      main: colors.narvik,
    },
    navyBlue: {
      main: colors.navyBlue,
    },
    dimGrey: {
      main: colors.dimGray,
    },
  },
})

theme = createTheme(theme, {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    allVariants: {
      color: theme.palette.blueBayoux.main,
    },
    subtitle1: {
      fontSize: '0.875rem',
    },
    subtitle2: {
      color: theme.palette.black.main,
      fontWeight: 300,
      fontSize: '0.8125rem',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '0.8125rem',
    },
  },
  zIndex: {
    modal: 10001,
    snackbar: 10001,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box', //TODO: remove when MuiCssBaseline will be replaced with CL
        },
        body: {
          overflowX: 'hidden',
        },
        ul: {
          margin: 0,
          padding: 0,
          listStyleType: 'none',
        },
        h5: {
          all: 'unset',
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        colorPrimary: theme.palette.thunderbird.main,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '2px 3px 10px 0px rgba(205, 206, 217, 0.5)',
          borderRadius: '10px',
          border: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.mediumBlue.main,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 24px 0 24px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.whiteSmoke.main,
          color: theme.palette.black.main,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiFormLabel: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: 'secondary',
        indicatorColor: 'secondary',
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'secondary',
        underline: 'hover',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          backgroundColor: theme.palette.mediumBlue.main,
          border: `1px solid ${theme.palette.darkGrey.main}`,
          borderRadius: '4px',
        },
        popper: {
          boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: theme.palette.mediumBlue.main,
          border: `1px solid ${theme.palette.darkGrey.main}`,
        },
      },
    },
  },
} as ThemeOptions)
