import { styled } from '@mui/material/styles'
import { WppCard } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const PageWrapper = styled(Flex)`
  margin: 24px 0;
  width: 100%;
`

export const UpdateForm = styled('form')`
  width: 100%;
`

export const CoverImageWrapper = styled(WppCard)`
  background: var(---wpp-grey-color-100);
  &::part(card) {
    width: 100%;
    max-height: 320px;
  }
`

export const CoverImage = styled('img')`
  object-fit: contain;
  width: 100%;
  height: 300px;
`
