import { useWidgetData } from 'pages/widget/hooks/useWidgetData'
import { WidgetDetailsPage } from 'pages/widget/update/WidgetDetailsPage'
import { CustomWppSpinner } from 'ui-base/spinner/Spinner'

export const WidgetDetailsContainer = () => {
  const { isWidgetLoading } = useWidgetData()

  if (isWidgetLoading) return <CustomWppSpinner size="l" data-testid="update-widget-page-spinner" />

  return <WidgetDetailsPage />
}
