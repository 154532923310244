import { WppIconTick, WppTag } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { ProductOnboardingStatus } from 'types/products/enums'
import { ProductOnboardingStatusLabel } from 'types/products/labels'

interface Props {
  status: MayBeNull<ProductOnboardingStatus>
}

export const ProductOnboardingTag = ({ status }: Props) => {
  const { t } = useTranslation(['products'])

  if (!status) {
    return null
  }

  const isNotDraft = status !== ProductOnboardingStatus.DRAFT
  const variant = isNotDraft ? 'positive' : 'neutral'

  return (
    <WppTag
      variant={variant}
      label={t(`products|onboarding_status.${ProductOnboardingStatusLabel[status]}`)}
      data-testid="publish-onboarding-status"
    >
      {isNotDraft && <WppIconTick slot="icon-start" />}
    </WppTag>
  )
}
