export enum API {
  MARKETPLACE = 'marketplace',
  DEVHUB = 'devhub',
  MASTER_DATA = 'master_data',
  CORE_FE_PROXY = 'core_fe',
}

export enum UpsertMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum AllTab {
  ALL = 'ALL',
}

export enum AppBaseUrls {
  'DEVHUB' = 'devhub',
  'MARKETPLACE' = 'marketplace',
}

export enum MessageTypes {
  'IMPORTANT' = 'important',
  'WARNING' = 'warning',
  'INFORMATION' = 'information',
}
