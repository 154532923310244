import { styled } from '@mui/material/styles'

interface SelectedSquaresProps {
  height?: string | number
  width?: string | number
}

export const ContentWrapper = styled('div')`
  padding: 8px;
  position: relative;
`

export const Square = styled('div')`
  width: 20px;
  height: 20px;
  background: var(--wpp-grey-color-400);
  border-radius: 2px;
`

export const SelectedSquares = styled('div')<SelectedSquaresProps>`
  position: absolute;
  background: var(--wpp-primary-color-300);
  height: ${props => `${props.height || 0}px`};
  width: ${props => `${props.width || 0}px`};
  border-radius: 2px;
`
